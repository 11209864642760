// redux/features/examMarkingSlice.ts

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { RootState } from "redux/store";

interface ExamMarkDetails {
  id: number;
  marks: number;
  student_id: number;
  exam_id: number;
}

interface UpdateExamMarkData {
  question_data: { question_id: number; marks: number }[];
  obtained_marks: number;
}

interface ExamMarkState {
  examMarkList: ExamMarkDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ExamMarkState = {
  examMarkList: [],
  isLoading: false,
  error: null,
};

// List Exam Marks Async here //


export const listExamMarksAsync = createAsyncThunk(
  "examMarks/list",
  async (
    {
      examId,
      page = 1,
      pageSize = 10,
      // searchQuery = "",
    }: {
      examId: number;
      page?: number;
      pageSize?: number;
      // searchQuery?: string;
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const filter: any = { exam_id: examId };
      // if (searchQuery) filter.name = searchQuery;

      const response = await axios.post(
        `${API_BASE_URL}/exam-marks/list`,
        {
          filter,
          range: { page, pageSize },
          sort: {},
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return {
          examMarks: response?.data?.data,
          count: response?.data?.count,
        };
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Update Exam Marks Async here  //
export const updateExamMarksAsync = createAsyncThunk(
  "examMarks/update",
  async (
    { Id, updateData }: { Id: number; updateData: UpdateExamMarkData },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/exam-marks/update/${Id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data;
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Update Exam Marks Slice here  //

const examMarkingSlice = createSlice({
  name: "examMarkingState",
  initialState,
  reducers: {
    clearExamMarkingError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listExamMarksAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listExamMarksAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.examMarkList = action.payload.examMarks;
      })
      .addCase(listExamMarksAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(updateExamMarksAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateExamMarksAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(updateExamMarksAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors //
export const { clearExamMarkingError } = examMarkingSlice.actions;
export const examMarkingReducer = examMarkingSlice.reducer;

export const selectExamMarkList = (state: RootState) =>
  state.examMarkingState.examMarkList;
export const selectExamMarkError = (state: RootState) =>
  state.examMarkingState.error;
export const selectExamMarkLoading = (state: RootState) =>
  state.examMarkingState.isLoading;

export default examMarkingSlice;



