import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  schoolList: [],
  isLoading: false,
  error: null,
};

// SchoolDetails here //
interface SchoolDetails {
  name: string;
  email: string;
  logo_url: string;
  address: string;
  country: string;
  state: string;
  city: string;
  contact_person: string;
  phone: string;
  sub_domain: string;
}

// Create School Async  here //
export const createSchoolAsync = createAsyncThunk(
  "school/create",
  async (credentials: SchoolDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/client/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create School Slice here //

const schoolSlice = createSlice({
  name: "schoolState",
  initialState,
  reducers: {
    schoolData: (state: any, action) => {
      state.schoolList.push(action.payload);
    },
    resetSchoolList: () => initialState,
    clearSchoolError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchoolAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSchoolAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.schoolList = [...state.schoolList, action.payload];
      })
      .addCase(createSchoolAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { resetSchoolList, clearSchoolError } = schoolSlice.actions;
export default schoolSlice.reducer;

export const selectSchoolDataList = (state: RootState) =>
  state.schoolState?.schoolList ?? [];

// school list //
interface ErrorPayload {
  message: string;
  status: number;
}

interface SchoolListDetails {
  id: number;
  name: string;
  sub_domain: string;
  city: string;
  contact_person: string;
  status: boolean;
}

interface FetchSchoolsArgs {
  schoolId?: number[];
  cityId?: string[];
  contactPersonId?: string[];
  subDomainId?: string[];
  statusId?: boolean | null;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
}

interface SchoolListResponse {
  schools: SchoolListDetails[];
  count: number;
}

// School list Async here //
export const SchoolListAsync = createAsyncThunk<
  SchoolListResponse,
  FetchSchoolsArgs,
  { rejectValue: ErrorPayload }
>(
  "school/create/list",
  async (
    {
      schoolId,
      cityId,
      contactPersonId,
      subDomainId,
      statusId,
      searchQuery,
      page,
      pageSize,
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const filter: any = {};
      if (schoolId) filter.id = schoolId;
      if (cityId) filter.city = cityId;
      if (contactPersonId) filter.contact_person = contactPersonId;
      if (subDomainId) filter.sub_domain = subDomainId;
      if (statusId !== null) filter.status = statusId;
      if (searchQuery) filter.name = searchQuery;

      const range: any = {};
      if (page !== undefined) range.page = page;
      if (pageSize !== undefined) range.pageSize = pageSize;

      const response = await axios.post(
        `${API_BASE_URL}/client/list`,
        { filter, range: Object.keys(range).length ? range : undefined },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        const schools = response?.data?.data;
        return { schools, count: response?.data?.count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

interface SchoolListState {
  schoolDataList: SchoolListDetails[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

const schoolListInitialState: SchoolListState = {
  schoolDataList: [],
  count: 0,
  isLoading: false,
  error: null,
};

// School list Slice here //

export const schoolListSlice = createSlice({
  name: "schoolListState",
  initialState: schoolListInitialState,
  reducers: {
    clearSchoolList: () => schoolListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SchoolListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        SchoolListAsync.fulfilled,
        (state, action: PayloadAction<SchoolListResponse>) => {
          state.isLoading = false;
          state.schoolDataList = action.payload.schools;
          state.count = action.payload.count;
        }
      )
      .addCase(SchoolListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearSchoolList } = schoolListSlice.actions;
export const schoolListReducer = schoolListSlice.reducer;
export const DisplaySchoolDataList = (state: RootState) =>
  state.schoolListState.schoolDataList ?? [];
export const selectSchoolListCount = (state: RootState) =>
  state.schoolListState.count;

// // update school values//
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateSchoolDetails {
  [x: string]: any;
  logo_url: string;
  name: string;
  address: string;
  country: string;
  state: string;
  city: string;
  contact_person: string;
  email: string;
  phone: string;
  landline: string;
  school_url: string;
  affiliated_to: string;
  affiliation_number: string;
  sub_domain: string;
}

interface UpdateSchoolState {
  updateSchoolList: UpdateSchoolDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateSchoolState = {
  updateSchoolList: [],
  isLoading: false,
  error: null,
};

// Update School Async here //
export const updateSchoolAsync = createAsyncThunk(
  "school/update",
  async (
    { id, schoolDetails }: { id: any; schoolDetails: UpdateSchoolDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/client/update/${id}`,
        schoolDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update school slice here //
export const updateSchoolSlice = createSlice({
  name: "updateSchoolState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedSchool: (state) => {
      state.updateSchoolList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateSchoolAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateSchoolAsync.fulfilled,
        (state, action: PayloadAction<UpdateSchoolDetails>) => {
          state.isLoading = false;
          const index = state.updateSchoolList.findIndex(
            (school) => school.id === action.payload.id
          );
          if (index !== -1) {
            state.updateSchoolList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateSchoolAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedSchool } = updateSchoolSlice.actions;
export const updateSchoolReducer = updateSchoolSlice.reducer;
export const selectUpdateSchoolDataList = (state: RootState) => {
  return state.updateSchoolState?.updateSchoolList ?? [];
};

//Delete School Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteSchoolState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteSchoolState = {
  isLoading: false,
  error: null,
};

// Delete School Async here //

export const deleteSchoolAsync = createAsyncThunk(
  "school/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/client/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete School Slice here //

export const deleteSchoolSlice = createSlice({
  name: "deleteSchoolState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteSchool: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteSchoolAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSchoolAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSchoolAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearDeleteSchool } = deleteSchoolSlice.actions;
export const deleteSchoolReducer = deleteSchoolSlice.reducer;

// handle school status //
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateSchoolStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateSchoolStatusState {
  updateSchoolStatusList: UpdateSchoolStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateSchoolStatusState = {
  updateSchoolStatusList: [],
  isLoading: false,
  error: null,
};

// School Status Changes Async here //
export const updateSchoolStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateSchoolStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("school/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("token");
  const dbToken = localStorage.getItem("db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/client/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Db-Token": dbToken,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// School Status Changes Slice here //

export const updateSchoolStatusSlice = createSlice({
  name: "updateSchoolStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedSchoolStatus: (state) => {
      state.updateSchoolStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateSchoolStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateSchoolStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateSchoolStatusList.findIndex(
            (school) => school.id === action.payload.id
          );
          if (index !== -1) {
            state.updateSchoolStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateSchoolStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateSchoolStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selctors here //
export const { clearUpdatedSchoolStatus } = updateSchoolStatusSlice.actions;
export const updateSchoolStatusReducer = updateSchoolStatusSlice.reducer;
export const selectUpdateSchoolStatusDataList = (state: RootState) => {
  return state.updateSchoolStatusState?.updateSchoolStatusList ?? [];
};
