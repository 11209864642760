import { lazy } from "react";
import { Loadable, MinimalLayout } from "components";

const AuthLogin = Loadable(lazy(() => import("pages/auth/login")));
const Error404 = Loadable(lazy(() => import("pages/auth/error")));
const PdfDownloadComponent = Loadable(
  lazy(() => import("pages/school-admin/question-papers/pdf-download"))
);
const ForgotPasswordComponent = Loadable(
  lazy(() => import("pages/auth/forgot-password"))
);
const OtpScreenComponent = Loadable(lazy(() => import("pages/auth/otp")));
const RecoverPasswordComponent = Loadable(
  lazy(() => import("pages/auth/recover-password"))
);
const ViewQuestionPaper = Loadable(
  lazy(() => import("pages/school-admin/question-papers/view-question-paper"))
);
const PdfPreview = Loadable(
  lazy(() => import("pages/school-admin/question-papers/pdf-preview"))
);


// Login Routes here //
const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "error",
      element: <Error404 />,
    },
    {
      path: "*",
      element: <Error404 />,
    },
    {
      path: "forgot-password",
      element: <ForgotPasswordComponent />,
    },
    {
      path: "otp",
      element: <OtpScreenComponent />,
    },
    {
      path: "recover-password",
      element: <RecoverPasswordComponent />,
    },
    {
      path: "/question-papers/view",
      element: <ViewQuestionPaper />,
    },
    {
      path: "/school-admin/question-papers/pdf/:id",
      element: <PdfDownloadComponent />,
    },
    {
      path: "/school-admin/question-papers/pdf-preview/:id",
      element: <PdfPreview />,
    },
  ],
};

export default LoginRoutes;
