
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Define the initial state and interfaces //
interface InstructionDetails {
  heading: string;
  content: string;
  grade_id: number[];
  subject_id: number[];
}

// interface InstructionListDetails //
interface InstructionListDetails {
  id: string;
  heading: string;
  content: string;
  grade_id: number[];
  subject_id: number[];
  status: boolean;
}

interface UpdateInstructionDetails {
  id: any;
  heading: string;
  content: string;
  grade_id: number[];
  subject_id: number[];
  status_id: boolean;
}

interface ErrorPayload {
  message: string;
  status: number;
}

interface InstructionState {
  instructionList: InstructionDetails[];
  isLoading: boolean;
  error: string | null;
}

interface InstructionListState {
  instructionDataList: InstructionListDetails[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

interface FetchInstructionsArgs {
  gradeId?: number[];
  subjectId?: number[];
  statusId?: boolean;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
}

interface InstructionListResponse {
  instructions: InstructionListDetails[];
  count: number;
}

const instructionListInitialState: InstructionListState = {
  instructionDataList: [],
  count: 0,
  isLoading: false,
  error: null,
};

interface UpdateInstructionState {
  updateInstructionList: UpdateInstructionDetails[];
  isLoading: boolean;
  error: string | null;
}

interface DeleteInstructionState {
  isLoading: boolean;
  error: string | null;
}

// Initial states
const initialInstructionState: InstructionState = {
  instructionList: [],
  isLoading: false,
  error: null,
};

interface InstructionListPayload {
  searchQuery?: string;
  gradeId?: number[];
  subjectId?: number[];
  chapterId?: number[];
  statusId?: boolean;
}

const initialUpdateState: UpdateInstructionState = {
  updateInstructionList: [],
  isLoading: false,
  error: null,
};

const initialDeleteState: DeleteInstructionState = {
  isLoading: false,
  error: null,
};

// Create Instruction Async here //
export const createInstructionAsync = createAsyncThunk(
  "instruction/create",
  async (credentials: InstructionDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/instruction/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Instruction Slice here //

const instructionSlice = createSlice({
  name: "instructionState",
  initialState: initialInstructionState,
  reducers: {
    instructionData: (state: any, action) => {
      state.instructionList.push(action.payload);
    },
    resetInstructionList: () => initialInstructionState,
    clearInstructionError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInstructionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createInstructionAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.instructionList = [...state.instructionList, action.payload];
      })
      .addCase(createInstructionAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// List Instructions Async here //

export const InstructionListAsync = createAsyncThunk<
  InstructionListResponse,
  FetchInstructionsArgs,
  { rejectValue: ErrorPayload }
>(
  "instruction/list",
  async (
    { searchQuery, gradeId, subjectId, statusId, page = 1, pageSize = 10 },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");


    const filter: any = {};
    if (searchQuery) filter.heading = searchQuery;
    if (gradeId) filter.grade_id = gradeId;
    if (subjectId) filter.subject_id = subjectId;
    if (statusId !== null) filter.status = statusId;

    try {
      const response = await axios.post(
        `${API_BASE_URL}/instruction/list`,
        {
          filter,
          range: {
            page,
            pageSize,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const instructions = response?.data?.data as InstructionListDetails[];
        return { instructions, count: response?.data?.count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// List Instructions Slice here //

const instructionListSlice = createSlice({
  name: "instructionListState",
  initialState: instructionListInitialState,
  reducers: {
    clearInstructionList: () => instructionListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(InstructionListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        InstructionListAsync.fulfilled,
        (state, action: PayloadAction<InstructionListResponse>) => {
          state.isLoading = false;
          state.instructionDataList = action.payload.instructions;
          state.count = action.payload.count;
        }
      )
      .addCase(InstructionListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Update Instruction Async here //
export const updateInstructionAsync = createAsyncThunk(
  "instruction/update",
  async (
    {
      id,
      instructionDetails,
    }: { id: any; instructionDetails: UpdateInstructionDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");


    try {
      const response = await axios.post(
        `${API_BASE_URL}/instruction/update/${id}`,
        instructionDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Update Instruction Slice here //

const updateInstructionSlice = createSlice({
  name: "updateInstructionState",
  initialState: initialUpdateState,
  reducers: {
    clearUpdatedInstruction: (state) => {
      state.updateInstructionList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateInstructionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateInstructionAsync.fulfilled,
        (state, action: PayloadAction<UpdateInstructionDetails>) => {
          state.isLoading = false;
          const index = state.updateInstructionList.findIndex(
            (instruction) => instruction.id === action.payload.id
          );
          if (index !== -1) {
            state.updateInstructionList[index] = action.payload;
          } else {
            state.updateInstructionList.push(action.payload);
          }
        }
      )
      .addCase(updateInstructionAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = (action.payload as { message: string }).message;
      });
  },
});

// Delete Instruction Async here //
export const deleteInstructionAsync = createAsyncThunk(
  "instruction/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");


    try {
      const response = await axios.post(
        `${API_BASE_URL}/instruction/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return id;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Instruction Slice here //

const deleteInstructionSlice = createSlice({
  name: "deleteInstructionState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteInstruction: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteInstructionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        deleteInstructionAsync.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.isLoading = false;
          state.error = null;
        }
      )
      .addCase(deleteInstructionAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Combine reducers //
export const { resetInstructionList, clearInstructionError } =
  instructionSlice.actions;
export const instructionReducer = instructionSlice.reducer;

export const { clearInstructionList } = instructionListSlice.actions;
export const instructionListReducer = instructionListSlice.reducer;

export const selectInstructionListCount = (state: RootState) =>
  state.instructionListState.count;

export const { clearUpdatedInstruction } = updateInstructionSlice.actions;
export const updateInstructionReducer = updateInstructionSlice.reducer;

export const { clearDeleteInstruction } = deleteInstructionSlice.actions;
export const deleteInstructionReducer = deleteInstructionSlice.reducer;

export const selectInstructionDataList = (state: RootState) =>
  state.instructionState?.instructionList ?? [];
export const DisplayInstructionDataList = (state: RootState) =>
  state.instructionListState?.instructionDataList ?? [];
export const selectUpdateInstructionDataList = (state: RootState) =>
  state.updateInstructionState?.updateInstructionList ?? [];

// update instruction status //
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateInstructionsStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateInstructionsStatusState {
  updateInstructionsStatusList: UpdateInstructionsStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateInstructionsStatusState = {
  updateInstructionsStatusList: [],
  isLoading: false,
  error: null,
};


// update Instructions status Async here // 
export const updateInstructionsStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateInstructionsStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("instructions/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("client_token");
  const db_token = localStorage.getItem("client_db_token");

  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/instruction/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token || SToken}`,
          "db-token": db_token || Sdb_token,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// update Instructions status Slice here // 

export const updateInstructionsStatusSlice = createSlice({
  name: "updateInstructionsStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedInstructionsStatus: (state) => {
      state.updateInstructionsStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateInstructionsStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateInstructionsStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateInstructionsStatusList.findIndex(
            (instructions) => instructions.id === action.payload.id
          );
          if (index !== -1) {
            state.updateInstructionsStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateInstructionsStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateInstructionsStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});


// Selectors //
export const { clearUpdatedInstructionsStatus } =
  updateInstructionsStatusSlice.actions;
export const updateInstructionsStatusReducer =
  updateInstructionsStatusSlice.reducer;
export const selectUpdateInstructionsStatusDataList = (state: RootState) => {
  return (
    state.updateInstructionsStatusState?.updateInstructionsStatusList ?? []
  );
};
