import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  createManualQuestionPaperList: [],
  isLoading: false,
  error: null,
};

// Section Details here //
interface SectionDetails {
  section: string;
  max_marks: number;
  no_of_ques: number;
  total_section_marks: number;
}

interface ManualCreateQuestionPaperDetails {
  grade_id: number | null;
  subject_id: number | null;
  type_test: string;
  question_paper_marks: number;
  passing_marks: number;
  distinction_marks: number;
  timing: string;
  data_json: SectionDetails[];
}

// Manual Create Question Paper Async here //
export const ManualCreateQuestionPaperAsync = createAsyncThunk(
  "question-draft/create",
  async (
    credentials: ManualCreateQuestionPaperDetails,
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/set-section`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Manual Create Question Paper Slice here //

const manualCreateQuestionPaperSlice = createSlice({
  name: "ManualCreateQuestionPaperState",
  initialState,
  reducers: {
    manualCreateQuestionPaperData: (state: any, action) => {
      state.createManualQuestionPaperList.push(action.payload);
    },
    resetManualCreateQuestionPaperList: () => initialState,
    clearManualCreateQuestionPaperError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(ManualCreateQuestionPaperAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        ManualCreateQuestionPaperAsync.fulfilled,
        (state: any, action) => {
          state.isLoading = false;
          state.createManualQuestionPaperList = [
            ...state.createManualQuestionPaperList,
            action.payload,
          ];
        }
      )
      .addCase(
        ManualCreateQuestionPaperAsync.rejected,
        (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );
  },
});

export const {
  manualCreateQuestionPaperData,
  resetManualCreateQuestionPaperList,
  clearManualCreateQuestionPaperError,
} = manualCreateQuestionPaperSlice.actions;

export default manualCreateQuestionPaperSlice.reducer;

export const selectManualCreateQuestionPaperDataList = (state: RootState) =>
  state.ManualCreateQuestionPaperState?.createManualQuestionPaperList ?? [];

// question paper list //
interface ErrorPayload {
  message: string;
  status: number;
}

interface QuestionPaperListDetails {
  filter: {
    id: number;
  };
}

interface QuestionPaperListState {
  questionPaperDataList: QuestionPaperListDetails[];
  isLoading: boolean;
  error: string | null;
}

const questionPaperListInitialState: QuestionPaperListState = {
  questionPaperDataList: [],
  isLoading: false,
  error: null,
};

// Manual list Question Paper Async here //

export const QuestionPaperListAsync = createAsyncThunk(
  "question-paper/create/list",
  async (payload: any, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/list`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const chapters = Object.values(response?.data?.data);
        return chapters;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Manual list Question Paper Slice here //

export const questionPaperListSlice = createSlice({
  name: "questionPapersListState",
  initialState: questionPaperListInitialState,
  reducers: {
    DisplayQuestionPaper: (
      state,
      action: PayloadAction<QuestionPaperListDetails>
    ) => {
      state.questionPaperDataList.push(action.payload);
    },
    clearQuestionPaperList: () => questionPaperListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(QuestionPaperListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(QuestionPaperListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questionPaperDataList =
          action.payload as QuestionPaperListDetails[];
      })
      .addCase(QuestionPaperListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors //
export const { clearQuestionPaperList, DisplayQuestionPaper } =
  questionPaperListSlice.actions;
export const questionPaperListReducer = questionPaperListSlice.reducer;

export const DisplayQuestionPaperDataList = (state: RootState) =>
  state.questionPapersListState?.questionPaperDataList ?? [];
