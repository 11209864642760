import React, { useEffect, useCallback } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { store, useTypedSelector } from "redux/store";
import { useNavigate, useParams } from "react-router-dom";
import {
  QuestionPaperPreviewAsync,
  selectQuestionPaperPreviewDataList,
} from "redux/features/QuestionPaperListSlice";
import DOMPurify from "dompurify";

// convert html tags in a proper way //
const stripPTags = (html) => {
  if (!html) return "";

  let temp = document.createElement("div");
  temp.innerHTML = html;

  return temp.textContent || temp.innerText || "";
};

// view Question paper component here //
const ViewQuestionPaperComponent = () => {
  const renderContent = useCallback((content = "") => {
    const sanitizedBlog = DOMPurify.sanitize(content);
    return <span dangerouslySetInnerHTML={{ __html: sanitizedBlog }}></span>;
  }, []);

  let dispatch = useDispatch<typeof store.dispatch>();
  const { id } = useParams();
  // List data here //
  const questionPaperData = useTypedSelector(
    selectQuestionPaperPreviewDataList
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(QuestionPaperPreviewAsync({ id }));
    }
  }, [id, dispatch]);

  if (!questionPaperData) {
    return <div>Loading...</div>;
  }

  const handleCancel = () => {
    navigate("/school-admin/question-papers");
  };

  // convert indexing in Letter like alphabet A , B, C //
  const indexToLetter = (index) => {
    return String.fromCharCode(65 + index);
  };

  // Tsx here //
  return (
    <section className="question-paper">
      <button
        onClick={handleCancel}
        className="custom-inactive-button rounded-lg"
      >
        Cancel
      </button>
      <div className="container-fluid">
        {questionPaperData ? (
          <div>
            <div className="top-area">
              <div className="left-area">
                <h6>
                  [Total No. of Questions: {questionPaperData?.total_questions}]
                </h6>
                <h4>{questionPaperData?.question_paper_code}</h4>
              </div>
              <div className="right-area">
                <h6>[Total No. of Pages: {questionPaperData?.total_pages}]</h6>
              </div>
            </div>
            <div className="subject-area">
              <h4>{questionPaperData?.header_1}</h4>
              <h4>{questionPaperData?.header_2}</h4>
              <h2>{questionPaperData?.grade?.title}</h2>
              <h2>{questionPaperData?.subject?.title}</h2>
              <h4>{questionPaperData.type_test}</h4>
              <h4>{questionPaperData?.question_paper_created_type}</h4>
            </div>
            <div className="exam-details-area">
              <p>{`[Time: ${questionPaperData?.timing}]`}</p>
              <p>{`[Max. Marks: ${questionPaperData?.question_paper_marks}]`}</p>
            </div>
            <div className="instruction-area">
              <p>Instructions to the candidates:-</p>
              <ul>
                {questionPaperData?.instruction?.heading && (
                  <li>
                    <span>{questionPaperData.instruction.heading}</span>
                  </li>
                )}
                {questionPaperData?.instruction?.content ? (
                  questionPaperData?.instruction?.content
                    .split("\n")
                    .filter((instruction) => instruction.trim() !== "")
                    ?.map((instruction, index) => (
                      <li key={index}>
                        <span>{`${index + 1}. `}</span>{" "}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: stripPTags(instruction),
                          }}
                        />
                      </li>
                    ))
                ) : (
                  <li>No Instructions</li>
                )}
              </ul>
            </div>


            <div className="question-area">
              {questionPaperData?.data_json?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                  {section?.section && section?.section !== "1" && (
                    <h3
                      style={{ textAlign: "center" }}
                    >{`Section ${section?.section}`}</h3>
                  )}
                  {section?.section && section?.section !== "1" && (
                  <div>
                    <h5>Instructions to the section:-</h5>{" "}
                    <p>{stripPTags(section?.instruction)}</p>
                  </div>
                  )}
                  {section?.questions?.map((question, questionIndex) => (
                    <div key={questionIndex} className="inner">
                      <div className="question-area-left">
                        <p>
                          {`Q${questionIndex + 1}. ${
                            stripPTags(question?.question_text) || "N/A"
                          }`}
                        </p>
                        <div className="options d-flex flex-column">
                          {question?.question_options?.map(
                            (option, optionIndex) => (
                              <p key={optionIndex} className="d-flex mb-0">
                                {indexToLetter(optionIndex)}.{" "}
                                {renderContent(
                                  option?.data?.data || option?.data
                                )}
                              </p>
                            )
                          )}
                        </div>
                        {question?.sub_questions?.map(
                          (subQuestion, subIndex) => (
                            <p key={subIndex}>{`${stripPTags(
                              subQuestion?.content
                            )}`}</p>
                          )
                        )}
                      </div>
                      <div className="question-area-right">
                        <p>[{question?.max_marks || 0} Marks]</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </section>
  );
};

export default ViewQuestionPaperComponent;

// ViewQuestionPaperComponent components done //
