import { Navigate, Outlet, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import Sidebar from "components/common/Sidebar";
import Topbar from "components/common/Topbar";
import { selectIsLoading, selectIsMobileView, selectIsShowSidebar,
   updateIsShowSidebar 
  } from "redux/features/appStateSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import LoadingCard from "components/common/LoadingCard";
import { getUser } from "helpers/storage";
import { useEffect, useState } from "react";

const MainLayout = () => {
  let location = useLocation();
  const isMobileView = useTypedSelector(selectIsMobileView);
  const isShowSidebar = useTypedSelector(selectIsShowSidebar);
  const user = getUser();
  const dispatch = useAppDispatch();
  const isLoading = useTypedSelector(selectIsLoading);
  const [showComps, setShowComps] = useState(true)

  if ((user ?? "").trim() === "") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  useEffect(() => {
    if (location.pathname.startsWith("/school-admin/question-papers/pdf/") || location.pathname.startsWith("/school-admin/question-papers/pdf-preview/")) {
      setShowComps(false);
    } else {
      setShowComps(true);
    }
  }, [location.pathname]);

  const setSidebar = () => {
    dispatch(updateIsShowSidebar({show: false}));
  }

  if(isLoading) return (<LoadingCard />)
  
  return (
    <Container fluid className={"p-0 m-0"}>
      <section>
        {showComps && <Sidebar showComps={showComps} /> }
        <div className={`main ${!(isShowSidebar && showComps) ? 'close' : ''}`}>
          {showComps && <Topbar showComps={showComps} /> }
          <div >
            <Outlet />
          </div>
        </div>
        <div className={`${!(isShowSidebar && showComps) ? '' : 'overlay'}`}></div>
      </section>
    </Container>
  );
};

export default MainLayout;