import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  topicList: [],
  isLoading: false,
  error: null,
};

interface TopicDetails {
  chapter_id: string;
  grade_id: string;
  subject_id: string;
  data_json: { title: string | ""; sub_topic: string | "" ; description: string | "" };
}

// Create Topic  Async here //
export const createTopicAsync = createAsyncThunk(
  "topic/create",
  async (credentials: TopicDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/subject-topic/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Topic  Slice here //

const topicSlice = createSlice({
  name: "topicState",
  initialState,
  reducers: {
    topicData: (state: any, action) => {
      state.topicList.push(action.payload);
    },
    resetTopicList: () => initialState,
    clearTopicError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTopicAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createTopicAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.topicList = [...state.topicList, action.payload];
      })
      .addCase(createTopicAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { resetTopicList, clearTopicError } = topicSlice.actions;
export default topicSlice.reducer;

export const selectTopicDataList = (state: RootState) =>
  state.topicState?.topicList ?? [];

// // topic list //
interface ErrorPayload {
  message: string;
  status: number;
}

interface TopicListDetails {
  id: number;
  title: string;
  sub_topic: string[];
  description: string;
  status: boolean;
  grades: string[];
  subjects: string[];
  chapter: string[];
}

interface TopicListState {
  topicDataList: TopicListDetails[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

const topicListInitialState: TopicListState = {
  topicDataList: [],
  count: 0,
  isLoading: false,
  error: null,
};

interface TopicListResponse {
  topics: TopicListDetails[];
  count: number;
}

interface FetchTopicsArgs {
  gradeId?: number[];
  subjectId?: number[];
  chapterId?: number[];
  topicId?: number[];
  subTopicId?: string[];
  statusId?: boolean;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
  isAllDataFetch?: boolean;
}

// Topic List Async here //
export const TopicListAsync = createAsyncThunk<
  TopicListResponse,
  FetchTopicsArgs,
  { rejectValue: ErrorPayload }
>(
  "topic/create/list",
  async (
    {
      gradeId,
      subjectId,
      chapterId,
      topicId,
      subTopicId,
      statusId,
      searchQuery,
      page,
      pageSize,
      isAllDataFetch,
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");

    try {
      const filter: any = {};
      if (gradeId) filter.grade_id = gradeId;
      if (subjectId) filter.subject_id = subjectId;
      if (chapterId) filter.chapter_id = chapterId;
      if (topicId) filter.id = topicId;
      if (subTopicId && subTopicId.length > 0) filter.sub_topic = subTopicId;
      if (statusId !== null) filter.status = statusId;
      if (searchQuery) filter.title = searchQuery;

      // Include is_all_data_fetch only when it is set to true
      if (isAllDataFetch) {
        filter.is_all_data_fetch = true;
      }

      const range: any = {};
      if (page !== undefined) range.page = page;
      if (pageSize !== undefined) range.pageSize = pageSize;

      const response = await axios.post(
        `${API_BASE_URL}/subject-topic/list`,
        {
          filter,
          range: Object.keys(range).length ? range : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken || reportToken}`,
            "db-token": db_token || Sdb_token || reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const topics = response?.data?.data as TopicListDetails[];
        return { topics, count: response?.data?.count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Topic List Slice here //

export const topicListSlice = createSlice({
  name: "topicListState",
  initialState: topicListInitialState,
  reducers: {
    clearTopicList: () => topicListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(TopicListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        TopicListAsync.fulfilled,
        (state, action: PayloadAction<TopicListResponse>) => {
          state.isLoading = false;
          state.topicDataList = action.payload.topics;
          state.count = action.payload.count;
        }
      )
      .addCase(TopicListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearTopicList } = topicListSlice.actions;
export const topicListReducer = topicListSlice.reducer;

export const DisplayTopicDataList = (state: RootState) =>
  state.topicListState.topicDataList ?? [];
export const selectTopicListCount = (state: RootState) =>
  state.topicListState.count;

// // update topic values//
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateTopicDetails {
  [x: string]: any;
  title: string;
  sub_topic: string;
  chapter_id: number[];
  grade_id: number[];
  subject_id: number[];
  description: string;
}

interface UpdateTopicState {
  updateTopicList: UpdateTopicDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateTopicState = {
  updateTopicList: [],
  isLoading: false,
  error: null,
};


// Update Topic Async here //
export const updateTopicAsync = createAsyncThunk(
  "topic/update",
  async (
    { id, topicDetails }: { id: any; topicDetails: UpdateTopicDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/subject-topic/update/${id}`,
        topicDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update topic slice here //
export const updateTopicSlice = createSlice({
  name: "updateTopicState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedTopic: (state) => {
      state.updateTopicList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateTopicAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateTopicAsync.fulfilled,
        (state, action: PayloadAction<UpdateTopicDetails>) => {
          state.isLoading = false;
          const index = state.updateTopicList.findIndex(
            (topic) => topic.id === action.payload.id
          );
          if (index !== -1) {
            state.updateTopicList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateTopicAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedTopic } = updateTopicSlice.actions;
export const updateTopicReducer = updateTopicSlice.reducer;
export const selectUpdateTopicDataList = (state: RootState) => {
  return state.updateTopicState?.updateTopicList ?? [];
};

//Delete topic Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteTopicState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteTopicState = {
  isLoading: false,
  error: null,
};

// Delete Topic Async here //
export const deleteTopicAsync = createAsyncThunk(
  "topic/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/subject-topic/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Topic Slice here //

export const deleteTopicSlice = createSlice({
  name: "deleteTopicState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteTopic: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteTopicAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteTopicAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteTopicAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearDeleteTopic } = deleteTopicSlice.actions;
export const deleteTopicReducer = deleteTopicSlice.reducer;

// handle topic status //
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateTopicStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateTopicStatusState {
  updateTopicStatusList: UpdateTopicStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateTopicStatusState = {
  updateTopicStatusList: [],
  isLoading: false,
  error: null,
};

// Topic change Status Async here //
export const updateTopicStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateTopicStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("topic/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("client_token");
  const db_token = localStorage.getItem("client_db_token");
  const SToken = localStorage.getItem("S_token");
  const Sdb_token = localStorage.getItem("S_db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/subject-topic/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token || SToken}`,
          "db-token": db_token || Sdb_token,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// Topic change Status Slice here //

export const updateTopicStatusSlice = createSlice({
  name: "updateTopicStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedTopicStatus: (state) => {
      state.updateTopicStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateTopicStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateTopicStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateTopicStatusList.findIndex(
            (topic) => topic.id === action.payload.id
          );
          if (index !== -1) {
            state.updateTopicStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateTopicStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateTopicStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedTopicStatus } = updateTopicStatusSlice.actions;
export const updateStatusReducer = updateTopicStatusSlice.reducer;
export const selectUpdateTopicStatusDataList = (state: RootState) => {
  return state.updateTopicStatusState?.updateTopicStatusList ?? [];
};

// upload topic  //
interface UploadTopicDetails {
  file: File;
  additionalData: any;
}

// Upload Topic Async here //
export const uploadTopicAsync = createAsyncThunk(
  "topic/upload",
  async ({ file, additionalData }: UploadTopicDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    const formData = new FormData();
    formData.append("file", file);
    for (const key in additionalData) {
      formData.append(key, additionalData[key]);
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/subject-topic/topicUpload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

interface UploadTopicState {
  isLoading: boolean;
  error: string | null;
}

const initialUploadState: UploadTopicState = {
  isLoading: false,
  error: null,
};

// Upload Topic Slice here //

export const uploadTopicSlice = createSlice({
  name: "uploadTopicState",
  initialState: initialUploadState,
  reducers: {
    clearUploadError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadTopicAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadTopicAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(uploadTopicAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { clearUploadError } = uploadTopicSlice.actions;

export const selectUploadTopicError = (state: RootState) =>
  state.uploadTopicState.error;
export const selectUploadTopicLoading = (state: RootState) =>
  state.uploadTopicState.isLoading;

