import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Exam Details here //
interface ExamDetails {
  grade_id: number;
  section_id: number[];
  question_paper_id: number;
  subject_id: number;
  exam_date: string;
  name: string;
  session_id: number;
  type: string;
}

interface ExamListDetails extends ExamDetails {
  id: number;
}

interface ExamState {
  examList: ExamListDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialState: ExamState = {
  examList: [],
  isLoading: false,
  error: null,
};

// Create Exam Async here //
export const createExamAsync = createAsyncThunk(
  "exam/create",
  async (examDetails: ExamDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/exam/create`,
        examDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data?.message);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// List Exams Async here //

export const listExamsAsync = createAsyncThunk(
  "exam/list",
  async (
    {
      searchQuery = "",
      page = 1,
      pageSize = 10,
      gradeId,
      sectionId,
      subjectId,
      examId,
      statusId,
      session_id,
    }: {
      searchQuery?: string;
      page?: number;
      pageSize?: number;
      gradeId?: number[];
      sectionId?: number[];
      subjectId?: number[];
      examId?: number[];
      statusId?: boolean | null;
      session_id?: number;
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const filter: any = { session_id };
      if (searchQuery) filter.name = searchQuery;
      if (gradeId && gradeId.length > 0) filter.grade_id = gradeId;
      if (sectionId && sectionId.length > 0) filter.section_id = sectionId;
      if (subjectId && subjectId.length > 0) filter.subject_id = subjectId;
      if (examId && examId.length > 0) filter.id = examId;
      if (statusId !== null) filter.status = statusId;

      const response = await axios.post(
        `${API_BASE_URL}/exam/list`,
        {
          filter,
          range: { page, pageSize },
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return {
          exams: response?.data?.data,
          count: response?.data?.count,
        };
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Edit Exam Async here //
export const updateExamAsync = createAsyncThunk(
  "exam/update",
  async (
    { id, examDetails }: { id: number; examDetails: ExamDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/exam/update/${id}`,
        examDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );
      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        const errorMessage = Array.isArray(response?.data?.message)
          ? response?.data?.message.join(", ")
          : response?.data?.message;
        toastError(errorMessage);
        return rejectWithValue(errorMessage);
      }
    } catch (error: any) {
      const errorMessage = Array.isArray(error.response?.data?.message)
        ? error.response?.data?.message.join(", ")
        : error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Exam Async here //
export const deleteExamAsync = createAsyncThunk(
  "exam/delete",
  async (
    { id, session_id }: { id: number; session_id: number },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/exam/delete/${id}`,
        { session_id },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data?.message);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

const examSlice = createSlice({
  name: "examState",
  initialState,
  reducers: {
    clearExamError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createExamAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createExamAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.examList.push(action.payload);
      })
      .addCase(createExamAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(listExamsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(listExamsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.examList = action.payload.exams;
      })
      .addCase(listExamsAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(updateExamAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateExamAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.examList.findIndex(
          (exam) => exam.id === action.payload.id
        );
        if (index !== -1) {
          state.examList[index] = action.payload;
        }
      })
      .addCase(updateExamAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteExamAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteExamAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.examList = state.examList.filter(
          (exam) => exam.id !== action.meta.arg
        );
      })
      .addCase(deleteExamAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { clearExamError } = examSlice.actions;
export const examReducer = examSlice.reducer;

export const selectExamList = (state: RootState) => state.examState.examList;
export const selectExamCount = (state: RootState) => state.examState.count;
export const selectExamError = (state: RootState) => state.examState.error;
export const selectExamLoading = (state: RootState) =>
  state.examState.isLoading;
