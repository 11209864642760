import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { loggedInUserDetailsAsync } from "./getDetailsLoggedInUserSlice";


// Login interfaces and Details here //
interface LoginState {
  loginList: any[];
  isLoading: boolean;
  error: string | null;
}

interface LoginCredentials {
  email: string;
  password: string;
}

interface LoginPayload {
  credentials: LoginCredentials;
  subdomain: string;
}

interface LoginSuccessResponse {
  token: string;
  db_token: string;
}

interface LoginRejectedValue {
  message: string;
  status: number;
}

const initialState: LoginState = {
  loginList: [],
  isLoading: false,
  error: null,
};


// Login Async here //
export const loginAsync = createAsyncThunk<
  LoginSuccessResponse,
  LoginPayload,
  { rejectValue: LoginRejectedValue }
>(
  "login/loginUser",
  async (
    { credentials, subdomain }: LoginPayload,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {},
      };

      if (subdomain) {
        config.headers["sub-domain"] = subdomain;
      }

      const response = await axios.post(
        `${API_BASE_URL}/login`,
        credentials,
        config
      );

      if (response.data.success) {
        const data: LoginSuccessResponse = {
          token: response?.data?.data?.token,
          db_token: response?.data?.data?.db_token,
        };

        if (subdomain) {
          localStorage.setItem("subdomain", subdomain);
        } else {
          localStorage.setItem("subdomain", "");
        }

        const tokenKey = subdomain ? "client_token" : "token";
        const dbTokenKey = subdomain ? "client_db_token" : "db_token";

        localStorage.setItem(tokenKey, data?.token);
        localStorage.setItem(dbTokenKey, data?.db_token);

        toastSuccess(response?.data?.message);
        await dispatch(loggedInUserDetailsAsync()).unwrap();
        return data;
      } else {
        toastError(response.data.message);
        return rejectWithValue({
          message: response.data.message,
          status: response.data.status,
        });
      }
    } catch (error: any) {
      const errorResponse = error.response?.data || {};
      const errorMessage =
        errorResponse.message || "An error occurred during login";
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: errorResponse.status || 500,
      });
    }
  }
);

// Login Slice here //

export const loginSlice = createSlice({
  name: "loginState",
  initialState,
  reducers: {
    loginData: (state, action: PayloadAction<any>) => {
      state.loginList.push(action.payload);
    },
    resetList: () => initialState,
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        loginAsync.fulfilled,
        (state, action: PayloadAction<LoginSuccessResponse>) => {
          state.isLoading = false;
          state.loginList.push(action.payload);
        }
      )
      .addCase(
        loginAsync.rejected,
        (state, action: PayloadAction<LoginRejectedValue | undefined>) => {
          state.isLoading = false;
          state.error = action.payload?.message;
        }
      );
  },
});

// Selectors here //
export const { resetList, loginData, clearError } = loginSlice.actions;

export default loginSlice.reducer;

export const selectedLoginDataList = (state: RootState) =>
  state.loginState?.loginList ?? [];
