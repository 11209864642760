import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface SessionState {
  selectedSessionId: string | null;
}

const initialState: SessionState = {
  selectedSessionId: null,
};

// Session1 Slice here //
export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSelectedSessionId: (state, action: PayloadAction<string>) => {
      state.selectedSessionId = action.payload;
    },
  },
});

export const { setSelectedSessionId } = sessionSlice.actions;

export const selectSelectedSessionId = (state: RootState) => state.session.selectedSessionId;

export default sessionSlice.reducer;
