import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "redux/store";
import { toastError } from "helpers/toastHelper";
import { API_BASE_URL } from "components/common/ApiUrl";

// Define the state interface //
interface SuperDashboardData {
  totalUsers: number;
  totalAdmins: number;
  totalSuperAdmins: number;
}

interface SuperDashboardState {
  data: SuperDashboardData | null;
  isLoading: boolean;
  error: string | null;
}

// Initial state //
const initialState: SuperDashboardState = {
  data: null,
  isLoading: false,
  error: null,
};

// super Admin Dashboard Async here //
export const fetchSuperDashboardDataAsync = createAsyncThunk(
  "superDashboard/fetchData",
  async (_, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}dashboard/count-master`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message || "Error fetching super dashboard data");
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// super Admin Dashboard Slice here //
const superDashboardSlice = createSlice({
  name: "superDashboardState",
  initialState,
  reducers: {
    clearSuperDashboardData: (state) => {
      state.data = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSuperDashboardDataAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSuperDashboardDataAsync.fulfilled,
        (state, action: PayloadAction<SuperDashboardData>) => {
          state.isLoading = false;
          state.data = action.payload;
        }
      )
      .addCase(
        fetchSuperDashboardDataAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearSuperDashboardData } = superDashboardSlice.actions;
export const superDashboardReducer = superDashboardSlice.reducer;
export const selectSuperDashboardData = (state: RootState) => state.superDashboardState.data;
export const selectSuperDashboardLoading = (state: RootState) => state.superDashboardState.isLoading;
export const selectSuperDashboardError = (state: RootState) => state.superDashboardState.error;
