import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { RootState } from "redux/store";
import { toastError, toastSuccess } from "helpers/toastHelper";

interface QuestionListDetails {
  id: number;
  grades: number[];
  subjects: number[];
  type: string[];
}

// Define the initial state interface //
interface QuestionPaperListState {
  questionPaperList: QuestionListDetails[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  totalPages: number;
  count: number;
}

// Initial state //
const initialState: QuestionPaperListState = {
  questionPaperList: [],
  status: "idle",
  error: null,
  totalPages: 0,
  count: 0,
};

// Question Paper List Async here //
export const fetchQuestionPaperListAsync = createAsyncThunk(
  "questionPaperList/fetchQuestionPaperList",
  async (
    {
      gradeId,
      subjectId,
      typeId,
      searchQuery,
      page = 1,
      pageSize = 10,
    }: {
      gradeId?: number[];
      subjectId?: number[];
      typeId?: string[];
      searchQuery?: string;
      page?: number;
      pageSize?: number;
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const dbToken = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const filter: any = {};
      if (gradeId) filter.grade_id = gradeId;
      if (subjectId) filter.subject_id = subjectId;
      if (typeId) filter.type_test = typeId;
      if (searchQuery) filter.type_test = searchQuery;

      const range: any = {};
      if (page !== undefined) range.page = page;
      if (pageSize !== undefined) range.pageSize = pageSize;

      const response = await axios.post(
        `${API_BASE_URL}/question-paper/list`,
        { filter, range: Object.keys(range).length ? range : undefined },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "Db-Token": dbToken || Sdb_token,
          },
        }
      );

      return response?.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// // Question Paper List Slice here //

export const QuestionPaperListSlice = createSlice({
  name: "questionPaperListState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestionPaperListAsync.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchQuestionPaperListAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionPaperList = action.payload.data || [];
        state.count = action.payload.count || 0;
        state.totalPages = Math.ceil(action.payload.count / 10) || 0;
      })
      .addCase(fetchQuestionPaperListAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as { message: string }).message;
      });
  },
});

export default QuestionPaperListSlice.reducer;

// Selectors //
export const selectQuestionPaperList = (state: RootState) =>
  state.questionPaperListState.questionPaperList;
export const selectQuestionPaperListStatus = (state: RootState) =>
  state.questionPaperListState.status;
export const selectQuestionPaperListError = (state: RootState) =>
  state.questionPaperListState.error;
export const selectQuestionPaperListCount = (state: RootState) =>
  state.questionPaperListState.count;
export const selectQuestionPaperListTotalPages = (state: RootState) =>
  state.questionPaperListState.totalPages;

// Question-paper-preview //
interface Instruction {
  heading: string;
  content: string;
}
interface Question {
  question_text: string;
  sub_questions?: { content: string }[];
  marks: number;
}

// Question Paper Preview Details here //
interface QuestionPaperPreviewDetails {
  id: number;
  type_test: string;
  timing: string;
  question_paper_marks: number;
  total_questions: string;
  total_pages: number;
  grade: { title: string };
  grade_id: number;
  subject: { title: string };
  subject_id: number;
  question_paper_code: string;
  question_paper_created_type: string;
  instruction: Instruction;
  data_json: { questions: Question[] }[];
}

interface QuestionPaperPreviewState {
  QuestionPaperPreviewList: QuestionPaperPreviewDetails | null;
  isLoading: boolean;
  error: string | null;
}

const initialQuestionPaperUpdatedState: QuestionPaperPreviewState = {
  QuestionPaperPreviewList: null,
  isLoading: false,
  error: null,
};

// Question Paper Preview Async here //

export const QuestionPaperPreviewAsync = createAsyncThunk(
  "question/paper/preview",
  async ({ id }: { id: any }, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/preview/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Question Paper Preview Slice here //

export const questionPaperPreviewSlice = createSlice({
  name: "questionPaperPreviewState",
  initialState: initialQuestionPaperUpdatedState,
  reducers: {
    clearQuestionPaperPreview: (state) => {
      state.QuestionPaperPreviewList = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(QuestionPaperPreviewAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        QuestionPaperPreviewAsync.fulfilled,
        (state, action: PayloadAction<QuestionPaperPreviewDetails>) => {
          state.isLoading = false;
          state.QuestionPaperPreviewList = action.payload;
        }
      )
      .addCase(
        QuestionPaperPreviewAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors //
export const { clearQuestionPaperPreview } = questionPaperPreviewSlice.actions;
export const questionPaperPreviewReducer = questionPaperPreviewSlice.reducer;
export const selectQuestionPaperPreviewDataList = (state: RootState) =>
  state.questionPaperPreviewState.QuestionPaperPreviewList;

// Pdf preview through marking scheme / without marking scheme ///
interface Instruction {
  heading: string;
  content: string;
}

interface QuestionPaperPdf {
  question_text: string;
  sub_questions?: { content: string }[];
  marks: number;
}
// Question Paper Pdf Details here//
interface QuestionPaperPdfDetails {
  id: number;
  type_test: string;
  timing: string;
  question_paper_marks: number;
  total_questions: string;
  total_pages: number;
  grade: { title: string };
  grade_id: number;
  subject: { title: string };
  subject_id: number;
  question_paper_code: string;
  question_paper_created_type: string;
  instruction: Instruction;
  data_json: { questions: Question[] }[];
}

interface QuestionPaperPdfState {
  QuestionPaperPdfList: QuestionPaperPdfDetails | null;
  isLoading: boolean;
  error: string | null;
}

const initialQuestionPaperPdfUpdatedState: QuestionPaperPdfState = {
  QuestionPaperPdfList: null,
  isLoading: false,
  error: null,
};

// Question Paper Pdf with marking - scheme Async here //
export const QuestionPaperPdfAsync = createAsyncThunk(
  "question/paper/pdf",
  async (
    { id, token, db_token }: { id: any; token: any; db_token: any },
    { rejectWithValue }
  ) => {
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/marking-scheme/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token ?? SToken}`,
            "db-token": db_token ?? Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Question Paper Pdf with marking - scheme Slice here //

export const questionPaperPdfSlice = createSlice({
  name: "questionPaperPdfState",
  initialState: initialQuestionPaperPdfUpdatedState,
  reducers: {
    clearQuestionPaperPdf: (state) => {
      state.QuestionPaperPdfList = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(QuestionPaperPdfAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        QuestionPaperPdfAsync.fulfilled,
        (state, action: PayloadAction<QuestionPaperPdfDetails>) => {
          state.isLoading = false;
          state.QuestionPaperPdfList = action.payload;
        }
      )
      .addCase(
        QuestionPaperPdfAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors //
export const { clearQuestionPaperPdf } = questionPaperPdfSlice.actions;
export const questionPaperPdfReducer = questionPaperPdfSlice.reducer;
export const selectQuestionPaperPdfDataList = (state: RootState) =>
  state.questionPaperPdfState.QuestionPaperPdfList;

// Pdf download through marking scheme/ without marking scheme ///
interface Instruction {
  heading: string;
  content: string;
}

interface QuestionPaperDownloadPdf {
  question_text: string;
  sub_questions?: { content: string }[];
  marks: number;
}

interface QuestionPaperDownloadPdfDetails {
  id: number;
  url: "string";
}

interface QuestionPaperDownloadPdfState {
  QuestionPaperDownloadPdfList: QuestionPaperDownloadPdfDetails | null;
  isLoading: boolean;
  error: string | null;
}

const initialQuestionPaperDownloadPdfUpdatedState: QuestionPaperDownloadPdfState =
  {
    QuestionPaperDownloadPdfList: null,
    isLoading: false,
    error: null,
  };

// Question Paper Pdf Download file Async here //

export const QuestionPaperDownloadPdfAsync = createAsyncThunk(
  "question/paper/download/pdf",
  async (
    {
      url,
      token,
      db_token,
      question_paper_id,
    }: {
      url: string;
      token: string;
      db_token: string;
      question_paper_id: string; 
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/media/download-file`,
        { url, question_paper_id }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "db-token": db_token,
          },
          responseType: "blob",
        }
      );
      if (response?.status === 200) {
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || "Unknown error occurred";
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Question Paper Pdf Download file Slice here //

export const questionPaperDownloadPdfSlice = createSlice({
  name: "questionPaperDownloadPdfState",
  initialState: initialQuestionPaperDownloadPdfUpdatedState,
  reducers: {
    clearQuestionPaperDownloadPdf: (state) => {
      state.QuestionPaperDownloadPdfList = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(QuestionPaperDownloadPdfAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        QuestionPaperDownloadPdfAsync.fulfilled,
        (state, action: PayloadAction<QuestionPaperDownloadPdfDetails>) => {
          state.isLoading = false;
          state.QuestionPaperDownloadPdfList = action.payload;
        }
      )
      .addCase(
        QuestionPaperDownloadPdfAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors //
export const { clearQuestionPaperDownloadPdf } =
  questionPaperDownloadPdfSlice.actions;
export const questionPaperDownloadPdfReducer =
  questionPaperDownloadPdfSlice.reducer;
export const selectQuestionPaperDownloadPdfDataList = (state: RootState) =>
  state.questionPaperDownloadPdfState.QuestionPaperDownloadPdfList;

// delete Question paper //

interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteQuestionPaperState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteQuestionPaperState = {
  isLoading: false,
  error: null,
};

// Delete Question Paper Async here //

export const deleteQuestionPaperAsync = createAsyncThunk(
  "question-paper/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Question Paper Slice here //

export const deleteQuestionPaperSlice = createSlice({
  name: "deleteQuestionPaperState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteQuestionPaper: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteQuestionPaperAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteQuestionPaperAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteQuestionPaperAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearDeleteQuestionPaper } = deleteQuestionPaperSlice.actions;
export const deleteQuestionPaperReducer = deleteQuestionPaperSlice.reducer;
