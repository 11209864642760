import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

interface LinkValidityState {
  isLoading: boolean;
  error: string | null;
  successMessage: string | null;
}

interface LinkValidityPayload {
  hash: string;
  subdomain: string;
}

const initialState: LinkValidityState = {
  isLoading: false,
  error: null,
  successMessage: null,
};

// User Validate Link Async here //
export const validateLinkAsync = createAsyncThunk<
  string,
  LinkValidityPayload,
  { rejectValue: { message: string; status: number } }
>("auth/validateLink", async (payload, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "sub-domain": payload.subdomain,
      },
    };

    const response = await axios.post(
      `${API_BASE_URL}/link-validity`,
      { hash: payload.hash },
      config
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return response?.data?.message;
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message, status: 400 });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({
      message: errorMessage,
      status: error.response?.status,
    });
  }
});

// User Validate Link Slice here //

const linkValiditySlice = createSlice({
  name: "linkValidityState",
  initialState,
  reducers: {
    clearLinkValidityError: (state) => {
      state.error = null;
    },
    clearLinkValiditySuccess: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateLinkAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(validateLinkAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = action.payload;
      })
      .addCase(validateLinkAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      });
  },
});

// Selectors here //
export const { clearLinkValidityError, clearLinkValiditySuccess } =
  linkValiditySlice.actions;
export default linkValiditySlice.reducer;

export const selectLinkValidityState = (state: RootState) =>
  state.linkValidityState;
export const selectLinkValidityError = (state: RootState) =>
  state.linkValidityState.error;
export const selectLinkValiditySuccessMessage = (state: RootState) =>
  state.linkValidityState.successMessage;
