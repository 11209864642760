import React, { useEffect, useState } from 'react';
import { getUser } from "helpers/storage";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const MinimalLayout = () => {
  const location = useLocation();
  const user = getUser();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  
  const allowedPaths = [
    "/login",
    "/verify",
    "/press-release",
    "/redirect-to-dashboard",
    "/press-release-preview",
    "/event/apicon",
    "/forgot-password",
    "/otp",
    "/recover-password",
  ];

  useEffect(() => {
    const isAllowedPath = allowedPaths.includes(location.pathname);
    const isPdfPath = location.pathname.startsWith("/school-admin/question-papers/pdf/") || location.pathname.startsWith("/school-admin/question-papers/pdf-preview/")
    
    setShouldRedirect(!(user || isAllowedPath || isPdfPath));
  }, [location.pathname, user]);

  if (shouldRedirect) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default MinimalLayout;