import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  schoolAdminList: [],
  isLoading: false,
  error: null,
};

// SchoolAdmin Details //
interface SchoolAdminDetails {
  school_name: string;
  email: string;
  school_admin_name: string;
  client_id: string;
}

// Create School Admin Details Async here //
export const createSchoolAdminAsync = createAsyncThunk(
  "school/admin/create",
  async (credentials: SchoolAdminDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/school/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create School Admin Details Slice here //

const schoolAdminSlice = createSlice({
  name: "schoolAdminState",
  initialState,
  reducers: {
    schoolAdminData: (state: any, action) => {
      state.schoolAdminList.push(action.payload);
    },
    resetSchoolAdminList: () => initialState,
    clearSchoolAdminError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchoolAdminAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSchoolAdminAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.schoolAdminList = [...state.schoolAdminList, action.payload];
      })
      .addCase(createSchoolAdminAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { resetSchoolAdminList, clearSchoolAdminError } =
  schoolAdminSlice.actions;
export default schoolAdminSlice.reducer;

export const selectSchoolAdminDataList = (state: RootState) =>
  state.schoolAdminState?.schoolAdminList ?? [];

// // school Admin list //
interface ErrorPayload {
  message: string;
  status: number;
}
interface SchoolAdminListDetails {
  id: number;
  school_admin_name: string;
  role: string;
  status: boolean;
}
interface SchoolAdminListState {
  schoolAdminDataList: SchoolAdminListDetails[];
  isLoading: boolean;
  error: string | null;
  count: number;
}

const schoolAdminListInitialState: SchoolAdminListState = {
  schoolAdminDataList: [],
  isLoading: false,
  error: null,
  count: 0,
};

interface FetchSchoolAdminsArgs {
  schoolId?: number[];
  roleId?: number[];
  schoolAdminId?: number[];
  statusId?: boolean | null;
  searchQuery?: string;
  page?: number;
  pageSize?: number;
}

// School Admin List Async here //
export const SchoolAdminListAsync = createAsyncThunk<
  { data: SchoolAdminListDetails[]; count: number },
  FetchSchoolAdminsArgs,
  { rejectValue: ErrorPayload }
>(
  "school/admin/list",
  async (
    {
      schoolId,
      roleId,
      schoolAdminId,
      statusId,
      searchQuery,
      page = 1,
      pageSize = 10,
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const filter: any = {};
      if (schoolId) filter.client_id = schoolId;
      if (roleId) filter.role_id = roleId;
      if (schoolAdminId) filter.id = schoolAdminId;
      if (statusId !== null) filter.status = statusId;
      if (searchQuery) filter.school_admin_name = searchQuery;

      const response = await axios.post(
        `${API_BASE_URL}/school/list`,
        {
          filter,
          range: {
            page,
            pageSize,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        const { data, count } = response?.data;
        return { data, count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// School Admin List Slice here //

export const schoolAdminListSlice = createSlice({
  name: "schoolAdminListState",
  initialState: schoolAdminListInitialState,
  reducers: {
    clearSchoolAdminList: () => schoolAdminListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SchoolAdminListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(SchoolAdminListAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.schoolAdminDataList = action.payload.data;
        state.count = action.payload.count;
      })
      .addCase(SchoolAdminListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selcetors here //
export const { clearSchoolAdminList } = schoolAdminListSlice.actions;
export const schoolAdminListReducer = schoolAdminListSlice.reducer;

export const DisplaySchoolAdminDataList = (state: RootState) =>
  state.schoolAdminListState?.schoolAdminDataList ?? [];

export const selectSchoolAdminListCount = (state: RootState) =>
  state.schoolAdminListState.count;

// // update school Admin values//
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateSchoolAdminDetails {
  [x: string]: any;
  school_name: string;
  school_admin_name: string;
  client_id: string;
}

interface UpdateSchoolAdminState {
  updateSchoolAdminList: UpdateSchoolAdminDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateSchoolAdminState = {
  updateSchoolAdminList: [],
  isLoading: false,
  error: null,
};

// Update School Admin Async here //
export const updateSchoolAdminAsync = createAsyncThunk(
  "school/update",
  async (
    {
      id,
      schoolAdminDetails,
    }: { id: any; schoolAdminDetails: UpdateSchoolAdminDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/school/update/${id}`,
        schoolAdminDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Update School Admin Slice here //
export const updateSchoolAdminSlice = createSlice({
  name: "updateSchoolAdminState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedSchoolAdmin: (state) => {
      state.updateSchoolAdminList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateSchoolAdminAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateSchoolAdminAsync.fulfilled,
        (state, action: PayloadAction<UpdateSchoolAdminDetails>) => {
          state.isLoading = false;
          const index = state.updateSchoolAdminList.findIndex(
            (schoolAdmin) => schoolAdmin.id === action.payload.id
          );
          if (index !== -1) {
            state.updateSchoolAdminList[index] = action.payload;
          }
        }
      )

      .addCase(
        updateSchoolAdminAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedSchoolAdmin } = updateSchoolAdminSlice.actions;
export const updateSchoolAdminReducer = updateSchoolAdminSlice.reducer;
export const selectUpdateSchoolAdminDataList = (state: RootState) => {
  return state.updateSchoolAdminState?.updateSchoolAdminList ?? [];
};

//Delete School Admin Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteSchoolAdminState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteSchoolAdminState = {
  isLoading: false,
  error: null,
};

// Delete School Admin Async here //
export const deleteSchoolAdminAsync = createAsyncThunk(
  "school/admin/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/school/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete School Admin Slice here //

export const deleteSchoolAdminSlice = createSlice({
  name: "deleteSchoolAdminState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteSchoolAdmin: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteSchoolAdminAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSchoolAdminAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSchoolAdminAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selctors here //
export const { clearDeleteSchoolAdmin } = deleteSchoolAdminSlice.actions;
export const deleteSchoolAdminReducer = deleteSchoolAdminSlice.reducer;

// handle school status //
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateSchoolAdminStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateSchoolAdminStatusState {
  updateSchoolAdminStatusList: UpdateSchoolAdminStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateSchoolAdminStatusState = {
  updateSchoolAdminStatusList: [],
  isLoading: false,
  error: null,
};

// Update School Admin Status Async here //
export const updateSchoolAdminStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateSchoolAdminStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("schooladmin/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("token");
  const dbToken = localStorage.getItem("db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/school/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Db-Token": dbToken,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});

// Update School Admin Status Slice here //

export const updateSchoolAdminStatusSlice = createSlice({
  name: "updateSchoolAdminStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedSchoolAdminStatus: (state) => {
      state.updateSchoolAdminStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateSchoolAdminStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateSchoolAdminStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateSchoolAdminStatusList.findIndex(
            (school) => school.id === action.payload.id
          );
          if (index !== -1) {
            state.updateSchoolAdminStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateSchoolAdminStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateSchoolAdminStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedSchoolAdminStatus } =
  updateSchoolAdminStatusSlice.actions;
export const updateSchoolAdminStatusReducer =
  updateSchoolAdminStatusSlice.reducer;
export const selectUpdateSchoolAdminStatusDataList = (state: RootState) => {
  return state.updateSchoolAdminStatusState?.updateSchoolAdminStatusList ?? [];
};



// Login As super-admin //
interface ErrorPayload {
  message: string;
  status: number;
}

const initialLoginState = {
  schoolAdminLoginList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface SchoolAdminLoginDetails {
  client_id: string;
  email: string;
}

// Login -as School Admin Async here //
export const schoolAdminLoginAsync = createAsyncThunk<
  any,
  SchoolAdminLoginDetails,
  { rejectValue: ErrorPayload }
>(
  "school/admin/login",
  async (credentials: SchoolAdminLoginDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/login-as`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);

        localStorage.setItem("S_token", response?.data?.access_token);
        localStorage.setItem("S_db_token", response?.data?.db_token);

        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Login -as School Admin Slice here //

export const schoolAdminLoginSlice = createSlice({
  name: "schoolAdminLoginState",
  initialState: initialLoginState,
  reducers: {
    schoolAdminLoginData: (state, action: PayloadAction<any>) => {
      state.schoolAdminLoginList.push(action.payload);
    },
    resetSchoolAdminLoginList: () => initialLoginState,
    clearSchoolAdminLoginError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(schoolAdminLoginAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        schoolAdminLoginAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.schoolAdminLoginList.push(action.payload);
        }
      )
      .addCase(schoolAdminLoginAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetSchoolAdminLoginList, clearSchoolAdminLoginError } =
  schoolAdminLoginSlice.actions;

export const selectSchoolAdminLoginDataList = (state: RootState) =>
  state.schoolAdminLoginState?.schoolAdminLoginList ?? [];
