import { ConfigureStoreOptions, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import appStateSlice from "./features/appStateSlice";
import createQuestionSlice, {
  deleteQuestionSlice,
  questionListSlice,
  similarQuestionSlice,
  updateQuestionSlice,
  updateQuestionStatusSlice,
  uploadQuestionSlice,
} from "./features/questionSlice";
import questionPaperSlice, {
  gridPreviewSlice,
} from "./features/manualQuestionPaperSlice";
import { userAssignRolesSlice } from "./features/assignUserRolesSlice";
import staffSlice, {
  deleteStaffSlice,
  staffListSlice,
  updateStaffSlice,
  updateStaffStatusSlice,
  uploadStaffSlice,
} from "./features/staffSlice";
import studentSlice, {
  deleteStudentSlice,
  studentListSlice,
  updateStudentSlice,
  updateStudentStatusSlice,
  uploadStudentSlice,
} from "./features/studentSlice";
import topicSlice, {
  deleteTopicSlice,
  topicListSlice,
  updateTopicSlice,
  updateTopicStatusSlice,
} from "./features/topicSlice";
import chaptersSlice, {
  chapterListSlice,
  deleteChapterSlice,
  updateChapterSlice,
  updateChapterStatusSlice,
} from "./features/chaptersSlice";
import subjectSlice, {
  deleteSubjectSlice,
  subjectListSlice,
  updateSubjectSlice,
} from "./features/subjectSlice";
import classesSlice, {
  classesListSlice,
  deleteClassesSlice,
  updateClassesSlice,
} from "./features/classesSlice";
import sectionsSlice, {
  sectionListSlice,
  updateSectionSlice,
  updateSectionStatusSlice,
} from "./features/sectionsSlice";
import sessionSlice, { sessionListSlice } from "./features/sessionSlice";
import userRolesSlice, {
  createRoleSlice,
  updateUserRolesStatusSlice,
  userRolesListSlice,
} from "./features/userRolesSlice";
import schoolAdminSlice, {
  schoolAdminListSlice,
  schoolAdminLoginSlice,
  updateSchoolAdminSlice,
  updateSchoolAdminStatusSlice,
} from "./features/schoolAdminSlice";
import loginSlice from "./features/loginSlice";
import { loggedUserDetailsSlice } from "./features/getDetailsLoggedInUserSlice";
import schoolSlice, {
  deleteSchoolSlice,
  schoolListSlice,
  updateSchoolSlice,
  updateSchoolStatusSlice,
} from "./features/schoolSlice";
import manageProfileSlice, {
  updateProfileSlice,
} from "./features/manageProfileSlice";
import manualCreateQuestionPaperSlice, {
  questionPaperListReducer,
  questionPaperListSlice,
} from "./features/filterQuestionPaperSlice";
import verifyOtpReducer from "./features/verifyOtpSlice";

import linkValidityReducer from "redux/features/validateLinkSlice";
import validateLinkReducer from "./features/validateLinkSlice";
import sendOtpReducer from "./features/sendOtpSlice";
import forgotPasswordSlice from "redux/features/forgotPasswordSlice";
import recoverPasswordSlice from "./features/recoverPasswordSlice";
import resetPasswordReducer from "./features/resetPasswordSlice";
import {
  deleteInstructionReducer,
  instructionListReducer,
  instructionReducer,
  updateInstructionReducer,
  updateInstructionsStatusSlice,
} from "./features/instructionSlice";
import {
  autoCreatePreviewReducer,
  autoCreateReducer,
  regenerateReducer,
  submitReducer,
} from "./features/autoCreateSlice";
import QuestionPaperListSlice, {
  deleteQuestionPaperSlice,
  questionPaperDownloadPdfSlice,
  questionPaperPdfSlice,
  questionPaperPreviewSlice,
} from "./features/QuestionPaperListSlice";
import moduleWiseAccessSlice from "./features/moduleWiseAccessSlice";
import { examReducer } from "./features/examSlice";
import { examMarkingReducer } from "./features/examMarkingSlice";
import {
  clearFileUploadState,
  fileUploadReducer,
} from "./features/fileUploadSice";

import { dashboardReducer } from "./features/dashboardSlice";
import { superDashboardReducer } from "./features/superDashboardSlice";
import sessionReducer from "redux/features/sessionSlice1";
import { examTableDataSlice, schoolAdminLoginForReportsSlice, schoolOverallPerformanceSlice } from "./features/reportSlice";

// Create Store here //
export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      appState: appStateSlice,
      loginState: loginSlice,
      loggedUserDetailsState: loggedUserDetailsSlice.reducer,
      questionPaperState: questionPaperSlice,
      userAssignRolesState: userAssignRolesSlice.reducer,
      classesState: classesSlice,
      classesListState: classesListSlice.reducer,
      updateClassesState: updateClassesSlice.reducer,
      deleteClassesState: deleteClassesSlice.reducer,
      sectionState: sectionsSlice,
      sectionListState: sectionListSlice.reducer,
      updateSectionState: updateSectionSlice.reducer,
      updateSectionStatusState: updateSectionStatusSlice.reducer,
      sessionState: sessionSlice,
      sessionListState: sessionListSlice.reducer,
      schoolState: schoolSlice,
      schoolListState: schoolListSlice.reducer,
      updateSchoolState: updateSchoolSlice.reducer,
      deleteSchoolState: deleteSchoolSlice.reducer,
      updateSchoolStatusState: updateSchoolStatusSlice.reducer,
      createRoleState: createRoleSlice.reducer,
      userRolesListState: userRolesListSlice.reducer,
      updateUserRolesStatusState: updateUserRolesStatusSlice.reducer,
      schoolAdminState: schoolAdminSlice,
      schoolAdminListState: schoolAdminListSlice.reducer,
      updateSchoolAdminState: updateSchoolAdminSlice.reducer,
      updateSchoolAdminStatusState: updateSchoolAdminStatusSlice.reducer,
      subjectState: subjectSlice,
      subjectListState: subjectListSlice.reducer,
      updateSubjectState: updateSubjectSlice.reducer,
      deleteSubjectState: deleteSubjectSlice.reducer,
      chapterState: chaptersSlice,
      chapterListState: chapterListSlice.reducer,
      updateChapterStatusState: updateChapterStatusSlice.reducer,
      updateChapterState: updateChapterSlice.reducer,
      deleteChapterState: deleteChapterSlice.reducer,
      staffState: staffSlice,
      staffListState: staffListSlice.reducer,
      updateStaffState: updateStaffSlice.reducer,
      updateStaffStatusState: updateStaffStatusSlice.reducer,
      deleteStaffState: deleteStaffSlice.reducer,
      topicState: topicSlice,
      topicListState: topicListSlice.reducer,
      updateTopicState: updateTopicSlice.reducer,
      deleteTopicState: deleteTopicSlice.reducer,
      updateTopicStatusState: updateTopicStatusSlice.reducer,
      uploadQuestionState: uploadQuestionSlice.reducer,
      uploadStudentState: uploadStudentSlice.reducer,
      uploadStaffState: uploadStaffSlice.reducer,
      session: sessionReducer,

      createQuestionState: createQuestionSlice,
      questionListState: questionListSlice.reducer,
      updateQuestionStatusState: updateQuestionStatusSlice.reducer,
      deleteQuestionState: deleteQuestionSlice.reducer,
      updateQuestionState: updateQuestionSlice.reducer,
      similarQuestionState: similarQuestionSlice.reducer,

      studentState: studentSlice,
      studentListState: studentListSlice.reducer,
      updateStudentStatusState: updateStudentStatusSlice.reducer,
      deleteStudentState: deleteStudentSlice.reducer,
      updateStudentState: updateStudentSlice.reducer,

      profileState: manageProfileSlice,
      updateProfileState: updateProfileSlice.reducer,
      ManualCreateQuestionPaperState: manualCreateQuestionPaperSlice,
      autoCreate: autoCreateReducer,
      autoCreatePreview: autoCreatePreviewReducer,
      regenerate: regenerateReducer,
      submit: submitReducer,

      forgotPasswordState: forgotPasswordSlice,

      linkValidityState: linkValidityReducer,
      validateLinkState: validateLinkReducer,
      sendOtpState: sendOtpReducer,
      verifyOtpState: verifyOtpReducer,
      recoverPasswordState: recoverPasswordSlice,
      resetPasswordState: resetPasswordReducer,
      instructionState: instructionReducer,
      instructionListState: instructionListReducer,
      updateInstructionState: updateInstructionReducer,
      deleteInstructionState: deleteInstructionReducer,
      updateInstructionsStatusState: updateInstructionsStatusSlice.reducer,
      gridPreviewState: gridPreviewSlice.reducer,
      questionPaperListState: QuestionPaperListSlice,
      questionPapersListState: questionPaperListReducer,
      deleteQuestionPaperState: deleteQuestionPaperSlice.reducer,
      schoolAdminLoginState: schoolAdminLoginSlice.reducer,
      moduleWiseAccessListState: moduleWiseAccessSlice,
      questionPaperPreviewState: questionPaperPreviewSlice.reducer,
      questionPaperPdfState: questionPaperPdfSlice.reducer,
      questionPaperDownloadPdfState: questionPaperDownloadPdfSlice.reducer,
      examState: examReducer,
      examMarkingState: examMarkingReducer,
      fileUploadState: fileUploadReducer,
      dashboardState: dashboardReducer,
      superDashboardState: superDashboardReducer,

      schoolAdminLoginReportsState:schoolAdminLoginForReportsSlice.reducer,

      schoolOverallPerformanceState:schoolOverallPerformanceSlice.reducer,
      examTableDataState:examTableDataSlice.reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
    ...options,
  });

export const store: any = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
