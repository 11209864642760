import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Login As super-admin functionality for student reports//
interface ErrorPayload {
  message: string;
  status: number;
}

const initialLoginState = {
  schoolAdminLoginList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface SchoolAdminLoginDetails {
  client_id: string;
  email: string;
}

// Login -as School Admin Async here //
export const schoolAdminLoginForReportsAsync = createAsyncThunk<
  any,
  SchoolAdminLoginDetails,
  { rejectValue: ErrorPayload }
>(
  "school/admin/login",
  async (credentials: SchoolAdminLoginDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/login-as`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        localStorage.setItem("reportToken", response?.data?.access_token);
        localStorage.setItem("reportdb_token", response?.data?.db_token);

        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Login -as School Admin Slice here //

export const schoolAdminLoginForReportsSlice = createSlice({
  name: "schoolAdminLoginReportsState",
  initialState: initialLoginState,
  reducers: {
    schoolAdminLoginData: (state, action: PayloadAction<any>) => {
      state.schoolAdminLoginList.push(action.payload);
    },
    resetSchoolAdminLoginList: () => initialLoginState,
    clearSchoolAdminLoginError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(schoolAdminLoginForReportsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        schoolAdminLoginForReportsAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.schoolAdminLoginList.push(action.payload);
        }
      )
      .addCase(schoolAdminLoginForReportsAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetSchoolAdminLoginList, clearSchoolAdminLoginError } =
  schoolAdminLoginForReportsSlice.actions;

export const selectSchoolAdminLoginDataList = (state: RootState) =>
  state.schoolAdminLoginReportsState?.schoolAdminLoginList ?? [];

// Overall performance reports//

const initialState = {
  schoolOverallPerformanceList: [],
  isLoading: false,
  error: null,
};


// School Over all performance Details here //
interface SchoolOverallPerformanceDetails {
  filter: {
    grade_id: number | null;
    section_id: number[] | null;
    subject_id: number | null;
    // chapter_id?: number | null;
    // topic_id?: number | null;
    type?: string | null;
  };
}

// Create School Async  here //
export const createSchoolOverallPerformanceAsync = createAsyncThunk(
  "school/overall/performance",
  async (credentials: SchoolOverallPerformanceDetails, { rejectWithValue }) => {
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/report/subject-performance`,
        { filter: credentials.filter },
        {
          headers: {
            Authorization: `Bearer ${reportToken}`,
            "Db-Token": reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess("Displaying Subject Wise Performance");
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create School overall performance Slice here //

export const schoolOverallPerformanceSlice = createSlice({
  name: "schoolOverallPerformanceState",
  initialState,
  reducers: {
    schoolOverallPerfomanceData: (state: any, action) => {
      state.schoolOverallPerformanceList.push(action.payload);
    },
    resetSchoolOverallPerformanceList: () => initialState,
    clearSchoolOverallPerformanceError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSchoolOverallPerformanceAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        createSchoolOverallPerformanceAsync.fulfilled,
        (state: any, action) => {
          state.isLoading = false;
          state.schoolOverallPerformanceList = [
            ...state.schoolOverallPerformanceList,
            action.payload,
          ];
        }
      )
      .addCase(
        createSchoolOverallPerformanceAsync.rejected,
        (state, action: any) => {
          state.isLoading = false;
          state.error = action.payload.message;
        }
      );
  },
});

// Selectors here //
export const {
  resetSchoolOverallPerformanceList,
  clearSchoolOverallPerformanceError,
} = schoolOverallPerformanceSlice.actions;
export default schoolOverallPerformanceSlice.reducer;

export const selectSchoolOverallPerformanceDataList = (state: RootState) =>
  state.schoolOverallPerformanceState?.schoolOverallPerformanceList ?? [];

// table showing based on particular exam id //
interface ErrorPayload {
  message: string;
  status: number;
}

const initialExamTableDataState = {
  examTableDataList: [] as any[],
  isLoading: false,
  error: null as string | null,
};

interface ExamTableDetails {
  exam_id: string;
}

// Login -as School Admin Async here //
export const examTableDataAsync = createAsyncThunk<
  any,
  ExamTableDetails,
  { rejectValue: ErrorPayload }
>(
  "exam/table/data",
  async (credentials: ExamTableDetails, { rejectWithValue }) => {
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/report/subject-performance/student`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${reportToken}`,
            "Db-Token": reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Exam table Data Slice here //

export const examTableDataSlice = createSlice({
  name: "examTableDataState",
  initialState: initialExamTableDataState,
  reducers: {
    // Update this to clear the data
    resetExamTableDataList: (state) => {
      state.examTableDataList = [];
    },
    clearExamTableDataError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(examTableDataAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        examTableDataAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.examTableDataList = action.payload;
        }
      )
      .addCase(examTableDataAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload && "message" in action.payload) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { resetExamTableDataList, clearExamTableDataError } =
  examTableDataSlice.actions;

export const selectExamTableDataDataList = (state: RootState) =>
  state.examTableDataState?.examTableDataList ?? [];
