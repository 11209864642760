const SToken = localStorage.getItem("S_token");
const Sdb_token = localStorage.getItem("S_db_token");

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

interface ErrorPayload {
  message: string;
  status: number;
}

interface AutoCreateState {
  autoCreateList: any[];
  isLoading: boolean;
  error: string | null;
}

interface AutoCreatePreviewState {
  autoCreateDataList: any[];
  isLoading: boolean;
  error: string | null;
}

// Initial states
const initialAutoCreateState: AutoCreateState = {
  autoCreateList: [],
  isLoading: false,
  error: null,
};

const initialAutoCreatePreviewState: AutoCreatePreviewState = {
  autoCreateDataList: [],
  isLoading: false,
  error: null,
};

// Create AutoCreate Async here //
export const createAutoCreateAsync = createAsyncThunk(
  "autoCreate/create",
  async (payload: any, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/auto-create/${payload?.id}`,
        payload?.data,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error?.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error?.response?.status,
      });
    }
  }
);


// Auto create Slice here //
const autoCreateSlice = createSlice({
  name: "autoCreate",
  initialState: initialAutoCreateState,
  reducers: {
    autoCreateData: (state, action: PayloadAction<any>) => {
      state.autoCreateList.push(action.payload);
    },
    resetAutoCreateList: () => initialAutoCreateState,
    clearAutoCreateError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAutoCreateAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createAutoCreateAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.autoCreateList.push(action.payload);
      })
      .addCase(createAutoCreateAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});


// Automatic question paper preview Async here // 
export const autoCreatePreviewAsync = createAsyncThunk(
  "autoCreate/preview",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/preview/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Auto question paper preview Slice here //
const autoCreatePreviewSlice = createSlice({
  name: "autoCreatePreview",
  initialState: initialAutoCreatePreviewState,
  reducers: {
    displayAutoCreate: (state, action: PayloadAction<any>) => {
      state.autoCreateDataList.push(action.payload);
    },
    clearAutoCreatePreview: () => initialAutoCreatePreviewState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(autoCreatePreviewAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(autoCreatePreviewAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.autoCreateDataList = action.payload;
      })
      .addCase(autoCreatePreviewAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Export actions and selectors
export const { autoCreateData, resetAutoCreateList, clearAutoCreateError } =
  autoCreateSlice.actions;

export const { displayAutoCreate, clearAutoCreatePreview } =
  autoCreatePreviewSlice.actions;

export const selectAutoCreateDataPreview = (state: RootState) => ({
  data: state.autoCreatePreview.autoCreateDataList,
  isLoading: state.autoCreatePreview.isLoading,
});
export const selectAutoCreateList = (state: RootState) =>
  state.autoCreate.autoCreateList;

// Export reducers
export const autoCreateReducer = autoCreateSlice.reducer;
export const autoCreatePreviewReducer = autoCreatePreviewSlice.reducer;



interface RegenerateState {
  regenerateList: any[];
  isLoading: boolean;
  error: string | null;
}

// Initial state
const initialRegenerateState: RegenerateState = {
  regenerateList: [],
  isLoading: false,
  error: null,
};

// Regenerate Async here //
export const regenerateAsync = createAsyncThunk(
  "regenerate/create",
  async (payload: any, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/regenerate/${payload.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Regenerate Slice here //
const regenerateSlice = createSlice({
  name: "regenerate",
  initialState: initialRegenerateState,
  reducers: {
    regenerateData: (state, action: PayloadAction<any>) => {
      state.regenerateList.push(action.payload);
    },
    resetRegenerateList: () => initialRegenerateState,
    clearRegenerateError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(regenerateAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(regenerateAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.regenerateList.push(action.payload);
      })
      .addCase(regenerateAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Export actions and selectors
export const { regenerateData, resetRegenerateList, clearRegenerateError } =
  regenerateSlice.actions;

export const selectRegenerateList = (state: RootState) =>
  state.regenerate.regenerateList;

// Export reducer
export const regenerateReducer = regenerateSlice.reducer;


interface SubmitState {
  submitList: any[];
  isLoading: boolean;
  error: string | null;
}

// Initial state
const initialSubmitState: SubmitState = {
  submitList: [],
  isLoading: false,
  error: null,
};

// Submit Async here //
export const submitAsync = createAsyncThunk(
  "submit/create",
  async (payload: any, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/submit/${payload.id}`,
        payload.data,
        {

          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Submit Slice here //

const submitSlice = createSlice({
  name: "submit",
  initialState: initialSubmitState,
  reducers: {
    submitData: (state, action: PayloadAction<any>) => {
      state.submitList.push(action.payload);
    },
    resetSubmitList: () => initialSubmitState,
    clearSubmitError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(submitAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.submitList.push(action.payload);
      })
      .addCase(submitAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Export actions and selectors
export const { submitData, resetSubmitList, clearSubmitError } =
  submitSlice.actions;

export const selectSubmitList = (state: RootState) =>
  state.submit.submitList;

// Export reducer
export const submitReducer = submitSlice.reducer;