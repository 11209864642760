import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL, SUB_DOMAIN } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

interface RecoverPasswordState {
  isLoading: boolean;
  error: string | null;
  successMessage: string | null;
}

interface RecoverPasswordPayload {
  hash: string;
  password: string;
  subdomain: string;
}

const initialState: RecoverPasswordState = {
  isLoading: false,
  error: null,
  successMessage: null,
};

// Recover Password Async here //
export const recoverPasswordAsync = createAsyncThunk<
  string,
  RecoverPasswordPayload,
  { rejectValue: { message: string; status: number } }
>(
  "auth/recoverPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "sub-domain": payload.subdomain,
        },
      };


      const response = await axios.post(
        `${API_BASE_URL}/set-password`,
        { hash: payload.hash, password: payload.password },
        config
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.message;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({ message: response?.data?.message, status: 400 });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Recover Password Slice here //

const recoverPasswordSlice = createSlice({
  name: "recoverPasswordState",
  initialState,
  reducers: {
    clearRecoverPasswordError: (state) => {
      state.error = null;
    },
    clearRecoverPasswordSuccess: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recoverPasswordAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(recoverPasswordAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.successMessage = action.payload;
      })
      .addCase(recoverPasswordAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.message;
      });
  },
});

// Selectors here //
export const { clearRecoverPasswordError, clearRecoverPasswordSuccess } = recoverPasswordSlice.actions;
export default recoverPasswordSlice.reducer;

export const selectRecoverPasswordState = (state: RootState) => state.recoverPasswordState;
export const selectRecoverPasswordError = (state: RootState) => state.recoverPasswordState.error;
export const selectRecoverPasswordSuccessMessage = (state: RootState) => state.recoverPasswordState.successMessage;
