import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SerializedError,
  } from "@reduxjs/toolkit";
  import axios from "axios";
  import { API_BASE_URL } from "components/common/ApiUrl";
  import { toastError, toastSuccess } from "helpers/toastHelper";
  import { RootState } from "redux/store";
  
  interface SendOtpState {
    isLoading: boolean;
    error: string | null;
    successMessage: string | null;
  }
  
  interface SendOtpPayload {
    hash: string;
    subdomain: string;
  }
  
  
  const initialState: SendOtpState = {
    isLoading: false,
    error: null,
    successMessage: null,
  };
  

  // Send otp Async here //
  export const sendOtpAsync = createAsyncThunk<
  string,
  SendOtpPayload,
  { rejectValue: { message: string; status: number } }
>(
  "auth/sendOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "sub-domain": payload.subdomain, 
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/sent-otp`,
        { hash: payload.hash },
        config 
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.message;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({ message: response?.data?.message, status: 400 });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

    // Send otp Slice here //

  const sendOtpSlice = createSlice({
    name: "sendOtpState",
    initialState,
    reducers: {
      clearSendOtpError: (state) => {
        state.error = null;
      },
      clearSendOtpSuccess: (state) => {
        state.successMessage = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(sendOtpAsync.pending, (state) => {
          state.isLoading = true;
          state.error = null;
          state.successMessage = null;
        })
        .addCase(sendOtpAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.successMessage = action.payload;
        })
        .addCase(sendOtpAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload?.message;
        });
    },
  });
  
  // Selectors here //
  export const { clearSendOtpError, clearSendOtpSuccess } = sendOtpSlice.actions;
  export default sendOtpSlice.reducer;
  
  export const selectSendOtpState = (state: RootState) => state.sendOtpState;
  export const selectSendOtpError = (state: RootState) => state.sendOtpState.error;
  export const selectSendOtpSuccessMessage = (state: RootState) => state.sendOtpState.successMessage;
  