const SToken = localStorage.getItem("S_token");
const Sdb_token = localStorage.getItem("S_db_token");

import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

interface ResetPasswordPayload {
  password: string;
  new_password: string;
}

interface ResetPasswordState {
  isLoading: boolean;
  error: string | null;
  success: boolean;
}

const initialState: ResetPasswordState = {
  isLoading: false,
  error: null,
  success: false,
};

// Reset Password Async here //
export const resetPasswordAsync = createAsyncThunk(
  "user/resetPassword",
  async (payload: ResetPasswordPayload, { rejectWithValue }) => {
    const token =
      localStorage.getItem("client_token") || localStorage.getItem("token");
    const dbToken =
      localStorage.getItem("client_db_token") ||
      localStorage.getItem("db_token");
    const subdomain = localStorage.getItem("subdomain");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/reset-password`,
        {
          password: payload.password,
          new_password: payload.new_password,
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "Db-Token": dbToken || Sdb_token,
            Subdomain: subdomain,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Reset Password Slice here //

export const resetPasswordSlice = createSlice({
  name: "resetPasswordState",
  initialState,
  reducers: {
    clearResetPasswordState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearResetPasswordState } = resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
export const selectResetPasswordState = (state: RootState) =>
  state.resetPasswordState;
