import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SerializedError,
  } from "@reduxjs/toolkit";
  import axios from "axios";
  import { API_BASE_URL } from "components/common/ApiUrl";
  import { toastError, toastSuccess } from "helpers/toastHelper";
  import { RootState } from "redux/store";
  
  interface VerifyOtpState {
    isLoading: boolean;
    error: string | null;
    successMessage: string | null;
  }
  
  interface VerifyOtpPayload {
    hash: string;
    emailOtp: string;
    subdomain: string;
  }
  
  
  const initialState: VerifyOtpState = {
    isLoading: false,
    error: null,
    successMessage: null,
  };
  

  // Verify Otp Async here //
  export const verifyOtpAsync = createAsyncThunk<
  string,
  VerifyOtpPayload,
  { rejectValue: { message: string; status: number } }
>(
  "auth/verifyOtp",
  async (payload, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "sub-domain": payload.subdomain,
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/verify-otp`,
        { hash: payload.hash, emailOtp: payload.emailOtp },
        config
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.message;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({ message: response?.data?.message, status: 400 });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

    // Verify Otp Slice here //

  const verifyOtpSlice = createSlice({
    name: "verifyOtpState",
    initialState,
    reducers: {
      clearVerifyOtpError: (state) => {
        state.error = null;
      },
      clearVerifyOtpSuccess: (state) => {
        state.successMessage = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(verifyOtpAsync.pending, (state) => {
          state.isLoading = true;
          state.error = null;
          state.successMessage = null;
        })
        .addCase(verifyOtpAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.successMessage = action.payload;
        })
        .addCase(verifyOtpAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload?.message;
        });
    },
  });
  
  // Seelctors here //
  export const { clearVerifyOtpError, clearVerifyOtpSuccess } = verifyOtpSlice.actions;
  export default verifyOtpSlice.reducer;
  
  export const selectVerifyOtpState = (state: RootState) => state.verifyOtpState;
  export const selectVerifyOtpError = (state: RootState) => state.verifyOtpState.error;
  export const selectVerifyOtpSuccessMessage = (state: RootState) => state.verifyOtpState.successMessage;
  