import { lazy } from "react";
import { Loadable, MainLayout, MinimalLayout } from "components";
import ViewQuestionPaperComponent from "pages/school-admin/question-papers/view-question-paper";
const PdfDownloadComponent = Loadable(
  lazy(() => import("pages/school-admin/question-papers/pdf-download"))
);

const SchoolReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/school-report"))
);
const ChapterReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/chapter-report"))
);
const ClassReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/class-report"))
);
const ClassWiseSectionReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/classWiseSection-report"))
);
const SubjectReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/subject-report"))
);
const TeacherReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/teacher-report"))
);
const TopicReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/topic-report"))
);
const TopicWiseTeacherReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/topicWiseTeacher-report"))
);

const Error404 = Loadable(lazy(() => import("pages/auth/error")));
const SchoolDashBoardComponent = Loadable(
  lazy(() => import("pages/school-admin/dashboard"))
);
const SuperDashBoardComponent = Loadable(
  lazy(() => import("pages/super-admin/dashboard"))
);
const QuestionClass = Loadable(
  lazy(() => import("pages/school-admin/classes"))
);
const QuestionSection = Loadable(
  lazy(() => import("pages/school-admin/sections"))
);
const QuestionSubject = Loadable(
  lazy(() => import("pages/school-admin/subjects"))
);
const QuestionChapter = Loadable(
  lazy(() => import("pages/school-admin/chapters"))
);
const QuestionTopic = Loadable(lazy(() => import("pages/school-admin/topics")));
const Question = Loadable(lazy(() => import("pages/school-admin/questions")));
const TeachersComponent = Loadable(
  lazy(() => import("pages/school-admin/staffs"))
);
const StudentsComponent = Loadable(
  lazy(() => import("pages/school-admin/students"))
);
// const AssignUserRolesComponent = Loadable(
//   lazy(() => import("pages/school-admin/assign-user-roles"))
// );
const QuestionPapers = Loadable(
  lazy(() => import("pages/school-admin/question-papers"))
);
const CreateManualQuestionPapers = Loadable(
  lazy(
    () =>
      import("pages/school-admin/question-papers/manual/create-question-paper")
  )
);
const CreateAutomaticQuestionPapers = Loadable(
  lazy(
    () =>
      import(
        "pages/school-admin/question-papers/automatic/create-question-paper"
      )
  )
);
const CreateQuestions = Loadable(
  lazy(() => import("pages/school-admin/create-questions"))
);
const ViewReportsComponent = Loadable(
  lazy(() => import("pages/super-admin/view-reports"))
);
const StudentReport = Loadable(
  lazy(() => import("pages/super-admin/view-reports/student-report"))
);
const ManageProfileComponent = Loadable(
  lazy(() => import("pages/school-admin/manage-profile"))
);
const PreviewAutomatic = Loadable(
  lazy(() => import("pages/school-admin/question-papers/automatic/preview"))
);
const ExamComponents = Loadable(lazy(() => import("pages/school-admin/exam")));
const ExamMarkingComponents = Loadable(
  lazy(() => import("pages/school-admin/exam-marking"))
);

const SessionComponent = Loadable(
  lazy(() => import("pages/super-admin/sessions"))
);
const SchoolComponent = Loadable(
  lazy(() => import("pages/super-admin/schools"))
);
const SchoolAdminsComponent = Loadable(
  lazy(() => import("pages/super-admin/school-admins"))
);
const RolesComponent = Loadable(lazy(() => import("pages/super-admin/roles")));
const ModuleWiseAccessComponent = Loadable(
  lazy(() => import("pages/super-admin/module-wise-access"))
);
// const ReportTemplatesComponent = Loadable(
//   lazy(() => import("pages/super-admin/report-templates"))
// );

const CreateTeacherQuestions = Loadable(
  lazy(() => import("pages/teacher-admin/create-questions"))
);
const ManageProfileTeacherComponent = Loadable(
  lazy(() => import("pages/teacher-admin/manage-profile"))
);
const TeacherQuestionPapers = Loadable(
  lazy(() => import("pages/teacher-admin/question-papers"))
);
const TeacherCreateManualQuestionPapersComponent = Loadable(
  lazy(
    () =>
      import("pages/teacher-admin/question-papers/manual/create-question-paper")
  )
);
const TeacherCreateManualAutomaticPapersComponent = Loadable(
  lazy(
    () =>
      import(
        "pages/teacher-admin/question-papers/automatic/create-question-paper"
      )
  )
);
const TeacherStudentsComponent = Loadable(
  lazy(() => import("pages/teacher-admin/students"))
);
const ManageInstructionsComponent = Loadable(
  lazy(() => import("pages/school-admin/manage-instructions"))
);
const ExamComponent = Loadable(lazy(() => import("pages/teacher-admin/exam")));
const ExamMarkingComponent = Loadable(
  lazy(() => import("pages/teacher-admin/exam-marking"))
);
const InstructionsComponent = Loadable(
  lazy(() => import("pages/teacher-admin/instructions"))
);
const ManageInstructionComponent = Loadable(
  lazy(() => import("pages/teacher-admin/manage-instruction"))
);

const ModuleWiseAccessComponents = Loadable(
  lazy(() => import("pages/school-admin/module-wise-access"))
);

const PdfPreview = Loadable(
  lazy(() => import("pages/school-admin/question-papers/pdf-preview"))
);

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/super-admin/dashboard",
      element: <SuperDashBoardComponent />,
    },
    {
      path: "/",
      element: <SchoolDashBoardComponent />,
    },
    {
      path: "/school-admin/dashboard",
      element: <SchoolDashBoardComponent />,
    },
    {
      path: "/school-admin/classes",
      element: <QuestionClass />,
    },
    {
      path: "/school-admin/sections",
      element: <QuestionSection />,
    },
    {
      path: "/school-admin/subjects",
      element: <QuestionSubject />,
    },
    {
      path: "/school-admin/chapters",
      element: <QuestionChapter />,
    },
    {
      path: "/school-admin/topics",
      element: <QuestionTopic />,
    },
    {
      path: "/school-admin/staffs",
      element: <TeachersComponent />,
    },
    {
      path: "/school-admin/students",
      element: <StudentsComponent />,
    },
    // {
    //   path: "/school-admin/assign-user-roles",
    //   element: <AssignUserRolesComponent />,
    // },
    {
      path: "/school-admin/questions",
      element: <Question />,
    },
    {
      path: "/school-admin/questions/create",
      element: <CreateQuestions />,
    },
    {
      path: "/school-admin/question-papers",
      element: <QuestionPapers />,
    },
    {
      path: "/school-admin/exam",
      element: <ExamComponents />,
    },
    {
      path: "/school-admin/exam/exam-marking",
      element: <ExamMarkingComponents />,
    },
    {
      path: "/school-admin/question-papers/manual/create",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/school-admin/question-papers/manual/create/:id",
      element: <CreateManualQuestionPapers />,
    },
    {
      path: "/school-admin/question-papers/automatic/create",
      element: <CreateAutomaticQuestionPapers />,
    },
    {
      path: "/school-admin/question-papers/automatic/preview",
      element: <PreviewAutomatic />,
    },
    {
      path: "/school-admin/manage-profile",
      element: <ManageProfileComponent />,
    },
    {
      path: "/school-admin/question-papers/preview/:id",
      element: <ViewQuestionPaperComponent />,
    },
    {
      path: "/school-admin/question-papers/pdf/:id",
      element: <PdfDownloadComponent />,
    },
    {
      path: "/school-admin/question-papers/pdf-preview/:id",
      element: <PdfPreview />,
    },
    {
      path: "/school-admin/manage-instruction",
      element: <ManageInstructionsComponent instructionList={undefined} />,
    },
    {
      path: "/school-admin/module-wise-access",
      element: <ModuleWiseAccessComponents />,
    },

    // super admin route starts from here //

    {
      path: "/super-admin/sessions",
      element: <SessionComponent />,
    },
    {
      path: "/super-admin/schools",
      element: <SchoolComponent />,
    },
    {
      path: "/super-admin/school-admins",
      element: <SchoolAdminsComponent />,
    },
    {
      path: "/super-admin/roles",
      element: <RolesComponent />,
    },
    {
      path: "/super-admin/module-wise-access",
      element: <ModuleWiseAccessComponent />,
    },
    // {
    //   path: "/super-admin/report-templates",
    //   element: <ReportTemplatesComponent />,
    // },
    {
      path: "/super-admin/view-reports/school-reports",
      element: <SchoolReport />,
    },
    {
      path: "/super-admin/view-reports/chapter-reports",
      element: <ChapterReport />,
    },
    {
      path: "/super-admin/view-reports/class-reports",
      element: <ClassReport />,
    },
    {
      path: "/super-admin/view-reports/class-section-reports",
      element: <ClassWiseSectionReport />,
    },
    {
      path: "/super-admin/view-reports/subject-reports",
      element: <SubjectReport />,
    },
    {
      path: "/super-admin/view-reports/student-reports",
      element: <StudentReport />,
    },
    {
      path: "/super-admin/view-reports/teacher-report",
      element: <TeacherReport />,
    },
    {
      path: "/super-admin/view-reports/topic-report",
      element: <TopicReport />,
    },
    {
      path: "/super-admin/view-reports/topic-teacher-report",
      element: <TopicWiseTeacherReport />,
    },

    // Teacher admin route starts froom here //

    {
      path: "/teacher-admin/questions",
      element: <CreateTeacherQuestions />,
    },
    {
      path: "/teacher-admin/manage-profile",
      element: <ManageProfileTeacherComponent />,
    },
    {
      path: "/teacher-admin/question-papers",
      element: <TeacherQuestionPapers />,
    },
    {
      path: "/teacher-admin/question-papers/manual/create",
      element: <TeacherCreateManualQuestionPapersComponent />,
    },
    {
      path: "/teacher-admin/question-papers/automatic/create",
      element: <TeacherCreateManualAutomaticPapersComponent />,
    },
    {
      path: "/teacher-admin/students",
      element: <TeacherStudentsComponent />,
    },
    {
      path: "/teacher-admin/exam",
      element: <ExamComponent />,
    },
    {
      path: "/teacher-admin/exam/exam-marking",
      element: <ExamMarkingComponent />,
    },
    {
      path: "/teacher-admin/instructions",
      element: <InstructionsComponent />,
    },
    {
      path: "/teacher-admin/instructions/manage-instruction",
      element: <ManageInstructionComponent />,
    },
    {
      path: "*",
      element: <Error404 />,
    },
  ],
};

export default MainRoutes;
