import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { PayloadAction, SerializedError } from "@reduxjs/toolkit";

interface ErrorPayload {
  message: string;
  status: number;
}

function isErrorPayload(object: any): object is ErrorPayload {
  return "message" in object && typeof object.message === "string";
}

interface SessionDetails {
  // name: string;
  start_date: string;
  end_date: string;
  description: string;
}

interface SessionState {
  sessionList: SessionDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialState: SessionState = {
  sessionList: [],
  isLoading: false,
  error: null,
};

// Create Session Async here //
export const createSessionAsync = createAsyncThunk(
  "session/create",
  async (credentials: SessionDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/session/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Session Slice here //

export const sessionSlice = createSlice({
  name: "sessionState",
  initialState,
  reducers: {
    addSession: (state, action) => {
      state.sessionList = [...state.sessionList, action.payload];
    },
    clearSessions: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSessionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSessionAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.sessionList = [...state.sessionList, action.payload];
        }
      })
      .addCase(createSessionAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (isErrorPayload(action.payload)) {
          state.error = action.payload.message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearSessions, addSession } = sessionSlice.actions;
export default sessionSlice.reducer;

export const selectedSessionDataList = (state: RootState) => {
  return state.sessionState?.sessionList ?? [];
};

// session list slice //

interface ErrorPayload {
  message: string;
  status: number;
}

interface SessionDetails {
  id: string;
  // name: string;
  start_date: string;
  end_date: string;
  description: string;
}

interface SessionListResponse {
  sessions: SessionDetails[];
  count: number;
}

interface FetchSessionsArgs {
  searchQuery?: string;
  page?: number;
  pageSize?: number;
}

interface SessionListState {
  sessionDataList: SessionDetails[];
  isLoading: boolean;
  error: string | null;
  count: number;
}

const sessionListInitialState: SessionListState = {
  sessionDataList: [],
  isLoading: false,
  error: null,
  count: 0,
};

// Session List Async here //
export const SessionListAsync = createAsyncThunk<
  SessionListResponse,
  FetchSessionsArgs,
  { rejectValue: ErrorPayload }
>(
  "session/create/list",
  async (
    { searchQuery, page = 1, pageSize = 10 }: FetchSessionsArgs,
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");
    const Token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const filter: any = {};
      if (searchQuery) filter.name = searchQuery;

      const response = await axios.post(
        `${API_BASE_URL}/session/list`,
        {
          filter,
          range: {
            page,
            pageSize,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${SToken || token || Token}` ,
            "Db-Token": Sdb_token || dbToken || db_token,
          },
        }
      );

      if (response?.data?.success) {
        return {
          sessions: Object.values(response?.data?.data),
          count: response?.data?.count,
        };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({
          message: response?.data?.message,
          status: response?.status,
        });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Session List Slice here //

export const sessionListSlice = createSlice({
  name: "sessionListState",
  initialState: sessionListInitialState,
  reducers: {
    DisplaySession: (state, action: PayloadAction<SessionDetails>) => {
      state.sessionDataList.push(action.payload);
    },
    clearSessionsList: () => sessionListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SessionListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        SessionListAsync.fulfilled,
        (state, action: PayloadAction<SessionListResponse>) => {
          state.isLoading = false;
          state.sessionDataList = action.payload.sessions;
          state.count = action.payload.count;
        }
      )
      .addCase(SessionListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selctors here //
export const { clearSessionsList, DisplaySession } = sessionListSlice.actions;
export const sessionListReducer = sessionListSlice.reducer;

export const DisplaySessionDataList = (state: RootState) =>
  state.sessionListState?.sessionDataList ?? [];
export const selectSessionListCount = (state: RootState) =>
  state.sessionListState.count;

// // update session values//
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateSessionDetails {
  [x: string]: any;
  // name: string;
  start_date: string;
  end_date: string;
  description: string;
}

interface UpdateSessionState {
  updateSessionList: UpdateSessionDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateSessionState = {
  updateSessionList: [],
  isLoading: false,
  error: null,
};

// Update Session Async here //
export const updateSessionAsync = createAsyncThunk(
  "session/update",
  async (
    {
      id,
      sessionDetails,
    }: { id: string; sessionDetails: UpdateSessionDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/session/update/${id}`,
        sessionDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update session slice here //
export const updateSessionSlice = createSlice({
  name: "updateSessionState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedSessions: (state) => {
      state.updateSessionList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateSessionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateSessionAsync.fulfilled,
        (state, action: PayloadAction<UpdateSessionDetails>) => {
          state.isLoading = false;
          const index = state.updateSessionList.findIndex(
            (session) => session.id === action.payload.id
          );
          if (index !== -1) {
            state.updateSessionList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateSessionAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedSessions } = updateSessionSlice.actions;
export const updateSessionReducer = updateSessionSlice.reducer;
export const selectUpdateSessionDataList = (state: RootState) => {
  return state.updateSessionState?.updateSessionList ?? [];
};



//Delete Session Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteSessionState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteSessionState = {
  isLoading: false,
  error: null,
};

// Delete Session Async here //

export const deleteSessionAsync = createAsyncThunk(
  "session/delete",
  async (ids: string | string[], { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const idArray = Array.isArray(ids) ? ids : [ids];
      const promises = idArray.map(async (id) => {
        const response = await axios.post(
          `${API_BASE_URL}/session/delete/${id}`, 
          null,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Db-Token": dbToken,
            },
          }
        );
        return response.data;
      });

      const responses = await Promise.all(promises);

      responses.forEach((response) => {
        if (!response?.success) {
          toastError(response?.message);
          throw new Error(response?.message);
        }
      });
      toastSuccess("Sessions deleted successfully");
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Session Slice here //

export const deleteSessionSlice = createSlice({
  name: "deleteSessionState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteSession: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteSessionAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSessionAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSessionAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearDeleteSession } = deleteSessionSlice.actions;
export const deleteSessionReducer = deleteSessionSlice.reducer;
