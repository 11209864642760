import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  classesList: [],
  isLoading: false,
  error: null,
};

interface ClassesDetails {
  title: string;
}

// Class Create Async here //
export const createClassAsync = createAsyncThunk(
  "class/create",
  async (credentials: ClassesDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/grade/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Class Create Slice here //

const createClassSlice = createSlice({
  name: "classesState",
  initialState,
  reducers: {
    classesData: (state: any, action) => {
      state.classesList.push(action.payload);
    },
    resetClassesList: () => initialState,
    clearClassesError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createClassAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createClassAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.classesList = [...state.classesList, action.payload];
      })
      .addCase(createClassAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

export const { resetClassesList, clearClassesError } = createClassSlice.actions;
export default createClassSlice.reducer;

export const selectClassesDataList = (state: RootState) =>
  state.classesState?.classesList ?? [];

// class list slice //
interface ErrorPayload {
  message: string;
  status: number;
}

interface ClassesListDetails {
  id: number;
  title: string;
  description: string;
}

interface ClassesListState {
  classesDataList: ClassesListDetails[];
  isLoading: boolean;
  error: string | null;
  totalPages: number;
  count: number;
}

const classesListInitialState: ClassesListState = {
  classesDataList: [],
  isLoading: false,
  error: null,
  totalPages: 0,
  count: 0,
};

// Class list Async here //
export const ClassesListAsync = createAsyncThunk(
  "classes/create/list",
  async (
    {
      searchQuery,
      page,
      pageSize,
    }: {
      searchQuery?: string;
      page?: number;
      pageSize?: number;
    },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");

    try {
      const filter: any = {};
      if (searchQuery) filter.title = searchQuery;

      const range: any = {};
      if (page !== undefined) range.page = page;
      if (pageSize !== undefined) range.pageSize = pageSize;

      const response = await axios.post(
        `${API_BASE_URL}/grade/list`,
        {
          filter,
          range: Object.keys(range).length ? range : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken || reportToken}`,
            "db-token": db_token || Sdb_token || reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        const classes = response?.data?.data;
        const count = response?.data?.count;
        return { classes, count };
      } else {
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Class list Slice here //

export const classesListSlice = createSlice({
  name: "classesListState",
  initialState: classesListInitialState,
  reducers: {
    clearClassesList: () => classesListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(ClassesListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        ClassesListAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.classesDataList = action.payload.classes;
          state.count = action.payload.count;
        }
      )
      .addCase(ClassesListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearClassesList } = classesListSlice.actions;
export const classesListReducer = classesListSlice.reducer;

export const DisplayClassesDataList = (state: RootState) =>
  state.classesListState.classesDataList ?? [];
export const selectClassListCount = (state: RootState) =>
  state.classesListState.count;

// // update classes values//
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateClassesDetails {
  [x: string]: any;
  title: string;
  description: string;
}

interface UpdateClassesState {
  updateClassesList: UpdateClassesDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateClassesState = {
  updateClassesList: [],
  isLoading: false,
  error: null,
};

// update Class Async here //
export const updateClassesAsync = createAsyncThunk(
  "classes/update",
  async (
    { id, classesDetails }: { id: any; classesDetails: UpdateClassesDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/grade/update/${id}`,
        classesDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update classes slice here//
export const updateClassesSlice = createSlice({
  name: "updateClassesState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedClasses: (state) => {
      state.updateClassesList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateClassesAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateClassesAsync.fulfilled,
        (state, action: PayloadAction<UpdateClassesDetails>) => {
          state.isLoading = false;
          const index = state.updateClassesList.findIndex(
            (classes) => classes.id === action.payload.id
          );
          if (index !== -1) {
            state.updateClassesList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateClassesAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

export const { clearUpdatedClasses } = updateClassesSlice.actions;
export const updateClassesReducer = updateClassesSlice.reducer;
export const selectUpdateClassesDataList = (state: RootState) => {
  return state.updateClassesState?.updateClassesList ?? [];
};

//Delete classes Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteClassesState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteClassesState = {
  isLoading: false,
  error: null,
};

// Delete Class Async here //
export const deleteClassesAsync = createAsyncThunk(
  "classes/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/grade/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Class Slice here //

export const deleteClassesSlice = createSlice({
  name: "deleteClassesState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteClasses: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteClassesAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteClassesAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteClassesAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearDeleteClasses } = deleteClassesSlice.actions;
export const deleteClassesReducer = deleteClassesSlice.reducer;
