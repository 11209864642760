import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

// Define the initial state interface //
interface QuestionPaperState {
  questionPaperList: any[];
  dragQuestionInPaper: any[];
  removeDragPrevQuestion: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

// Initial state //
const initialState: QuestionPaperState = {
  questionPaperList: [],
  dragQuestionInPaper: [],
  removeDragPrevQuestion: [],
  status: "idle",
  error: null,
};

// Async thunk for creating a manual question paper //
export const createManualQuestionPaper = createAsyncThunk(
  "questionPaper/createManualQuestionPaper",
  async ({ id, data }: { id: string; data: any }, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/manual-create/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "Db-Token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return { ...response?.data, id: response?.data?.id };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// create Manual Question paper Slice here  //
export const questionPaperSlice = createSlice({
  name: "questionPaperState",
  initialState,
  reducers: {
    sendData: (state, action: PayloadAction<any>) => {
      state.questionPaperList = [action.payload, ...state.questionPaperList];
    },
    sendDragData: (state, action: PayloadAction<any>) => {
      state.dragQuestionInPaper = [
        action.payload,
        ...state.dragQuestionInPaper,
      ];
    },
    removeDragInPrevQuestion: (state, action: PayloadAction<any>) => {
      state.removeDragPrevQuestion = [
        action.payload,
        ...state.removeDragPrevQuestion,
      ];
    },
    resetList: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManualQuestionPaper.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createManualQuestionPaper.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionPaperList.push(action.payload);
      })
      .addCase(createManualQuestionPaper.rejected, (state, action) => {
        state.status = "failed";
        state.error = (action.payload as { message: string }).message;
      });
  },
});

export const { sendData, resetList, sendDragData, removeDragInPrevQuestion } =
  questionPaperSlice.actions;

export default questionPaperSlice.reducer;

export const SelectedQuestionPaperList = (state: RootState) =>
  state.questionPaperState.questionPaperList;
export const SelectedDragQuestionPaperList = (state: RootState) =>
  state.questionPaperState.dragQuestionInPaper;
export const SelectedRemoveDragQuestionList = (state: RootState) =>
  state.questionPaperState.removeDragPrevQuestion;

// Grid preview //
interface GridPreviewDetails {
  headers: string[];
  data: {
    [key: string]: any;
  }[];
}

interface GridPreviewState {
  GridPreviewList: GridPreviewDetails | null;
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: GridPreviewState = {
  GridPreviewList: null,
  isLoading: false,
  error: null,
};

// Grid Preview Asnyc here //
export const GridPreviewAsync = createAsyncThunk(
  "grid/preview",
  async ({ id }: { id: any }, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");

    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/question-paper/grid-view/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return response?.data?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Grid Preview Slice here //

export const gridPreviewSlice = createSlice({
  name: "gridPreviewState",
  initialState: initialUpdatedState,
  reducers: {
    clearGridPreview: (state) => {
      state.GridPreviewList = null;
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GridPreviewAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        GridPreviewAsync.fulfilled,
        (state, action: PayloadAction<GridPreviewDetails>) => {
          state.isLoading = false;
          state.GridPreviewList = action.payload;
        }
      )
      .addCase(
        GridPreviewAsync.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearGridPreview } = gridPreviewSlice.actions;
export const gridPreviewReducer = gridPreviewSlice.reducer;
export const selectGridPreviewDataList = (state: RootState) =>
  state.gridPreviewState.GridPreviewList;
