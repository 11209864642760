import {
  createAsyncThunk,
  createSlice,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL, SUB_DOMAIN } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { PayloadAction } from "@reduxjs/toolkit";

interface ErrorPayload {
  message: string;
  status: number;
}

interface CreateRoleDetails {
  title: string;
}
interface CreateRoleState {
  userRolesList: Array<any>;
  isLoading: boolean;
  error: string | null;
}

const initialState: CreateRoleState = {
  userRolesList: [],
  isLoading: false,
  error: null,
};

// Create Roles Async here //
export const createRoleAsync = createAsyncThunk(
  "roles/create",
  async (roleDetails: CreateRoleDetails, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const dbToken = localStorage.getItem("db_token");

      const response = await axios.post(
        `${API_BASE_URL}/role/create`,
        roleDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Roles Slice here //

export const createRoleSlice = createSlice({
  name: "createRoleState",
  initialState,
  reducers: {
    userRolesData: (state, action: PayloadAction<any>) => {
      state.userRolesList = [...state.userRolesList, action.payload];
    },
    resetList: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(createRoleAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createRoleAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.userRolesList.push(action.payload);
      })
      .addCase(createRoleAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { userRolesData, resetList } = createRoleSlice.actions;
export default createRoleSlice.reducer;
export const selectedUserRolesDataList = (state: RootState) =>
  state.userRolesState?.userRolesList ?? [];


// user Roles list //
interface ErrorPayload {
  message: string;
  status: number;
}

interface UserRolesListDetails {
  id: string;
  title: string;
  status: boolean;
}

interface UserRolesListState {
  userRolesDataList: UserRolesListDetails[];
  count: number;
  isLoading: boolean;
  error: string | null;
}

const userRolesListInitialState: UserRolesListState = {
  userRolesDataList: [],
  count: 0,
  isLoading: false,
  error: null,
};

interface FetchUserRolesArgs {
  searchQuery?: string;
  page?: number;
  pageSize?: number;
  statusId?: boolean;
}

interface UserRolesListResponse {
  roles: UserRolesListDetails[];
  count: number;
}

// User Roles List Async here //
export const  UserRolesListAsync = createAsyncThunk<
  UserRolesListResponse,
  FetchUserRolesArgs,
  { rejectValue: ErrorPayload }
>(
  "userRole/create/list",
  async (
    { statusId, searchQuery, page = 1, pageSize = 10 },
    { rejectWithValue }
  ) => {
    const token =
      localStorage.getItem("client_token") || localStorage.getItem("token");
    const dbToken =
      localStorage.getItem("client_db_token") ||
      localStorage.getItem("db_token");

    try {
      const filter: any = {
        created_for: SUB_DOMAIN ? "SCHOOL" : "SUPER",
      };
      if (searchQuery) filter.title = searchQuery;
      filter.status = statusId;

      const response = await axios.post(
        `${API_BASE_URL}/role/list`,
        {
          filter,
          range: {
            page,
            pageSize,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        const roles = response?.data?.data;
        return { roles, count: response?.data?.count };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// User Roles List Slice here //

export const userRolesListSlice = createSlice({
  name: "userRolesListState",
  initialState: userRolesListInitialState,
  reducers: {
    clearUserRolesList: () => userRolesListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserRolesListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        UserRolesListAsync.fulfilled,
        (state, action: PayloadAction<UserRolesListResponse>) => {
          state.isLoading = false;
          state.userRolesDataList = action.payload.roles;
          state.count = action.payload.count;
        }
      )
      .addCase(UserRolesListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const { clearUserRolesList } = userRolesListSlice.actions;
export const userRolesListReducer = userRolesListSlice.reducer;

export const DisplayUserRolesDataList = (state: RootState) =>
  state.userRolesListState.userRolesDataList ?? [];
export const selectUserRolesListCount = (state: RootState) =>
  state.userRolesListState.count;



// // update userRoles values//
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateUserRolesDetails {
  [x: string]: any;
  title: string;
}

interface UpdateUserRolesState {
  updateUserRolesList: UpdateUserRolesDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateUserRolesState = {
  updateUserRolesList: [],
  isLoading: false,
  error: null,
};

// Update User Roles Async here //
export const updateUserRolesAsync = createAsyncThunk(
  "roles/update",
  async (
    {
      id,
      UserRolesDetails,
    }: { id: any; UserRolesDetails: UpdateUserRolesDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/role/update/${id}`,
        UserRolesDetails,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Update User Roles Skice here //
export const updateUserRolesSlice = createSlice({
  name: "updateUserRolesState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedUserRoles: (state) => {
      state.updateUserRolesList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateUserRolesAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateUserRolesAsync.fulfilled,
        (state, action: PayloadAction<UpdateUserRolesDetails>) => {
          state.isLoading = false;
          const index = state.updateUserRolesList.findIndex(
            (school) => school.id === action.payload.id
          );
          if (index !== -1) {
            state.updateUserRolesList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateUserRolesAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedUserRoles } = updateUserRolesSlice.actions;
export const updateUserRolesReducer = updateUserRolesSlice.reducer;
export const selectUpdateUserRolesDataList = (state: RootState) => {
  return state.updateUserRolesState?.updateUserRolesList ?? [];
};


//Delete user roles Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteUserRolesState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteUserRolesState = {
  isLoading: false,
  error: null,
};

// Delete User Roles Async here //
export const deleteUserRolesAsync = createAsyncThunk(
  "userRoles/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    const dbToken = localStorage.getItem("db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/role/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Db-Token": dbToken,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// // Delete User Roles Slice here //

export const deleteUserRolesSlice = createSlice({
  name: "deleteUserRolesState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteUserRoles: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUserRolesAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteUserRolesAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteUserRolesAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearDeleteUserRoles } = deleteUserRolesSlice.actions;
export const deleteUserRolesReducer = deleteUserRolesSlice.reducer;


// handle roles status //
interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateUserRolesStatusDetails {
  id: any;
}
interface RejectValue {
  message: string;
}

interface UpdateUserRolesStatusState {
  updateUserRolesStatusList: UpdateUserRolesStatusDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedStatusState: UpdateUserRolesStatusState = {
  updateUserRolesStatusList: [],
  isLoading: false,
  error: null,
};


// User Roles Change Status Async here //
export const updateUserRolesStatusAsync = createAsyncThunk<
  { id: number; active: boolean },
  UpdateUserRolesStatusDetails,
  {
    state: RootState;
    rejectValue: RejectValue;
  }
>("user/roles/update/status", async ({ id }, { rejectWithValue }) => {
  const token = localStorage.getItem("token");
  const dbToken = localStorage.getItem("db_token");

  try {
    const response = await axios.post(
      `${API_BASE_URL}/role/change-status/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Db-Token": dbToken,
        },
      }
    );

    if (response?.data?.success) {
      toastSuccess(response?.data?.message);
      return { id, active: response?.data?.data?.status };
    } else {
      toastError(response?.data?.message);
      return rejectWithValue({ message: response?.data?.message });
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message;
    toastError(errorMessage);
    return rejectWithValue({ message: errorMessage });
  }
});


// User Roles Change Status Slice here //

export const updateUserRolesStatusSlice = createSlice({
  name: "updateUserRolesStatusState",
  initialState: initialUpdatedStatusState,
  reducers: {
    clearUpdatedUserRolesStatus: (state) => {
      state.updateUserRolesStatusList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateUserRolesStatusAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateUserRolesStatusAsync.fulfilled,
        (state, action: PayloadAction<{ id: number; active: boolean }>) => {
          const index = state.updateUserRolesStatusList.findIndex(
            (userRoles) => userRoles.id === action.payload.id
          );
          if (index !== -1) {
            state.updateUserRolesStatusList[index].currentStatus =
              action.payload.active;
          } else {
            state.updateUserRolesStatusList.push({
              id: action.payload.id,
              currentStatus: action.payload.active,
            });
          }
          state.isLoading = false;
        }
      )
      .addCase(
        updateUserRolesStatusAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedUserRolesStatus } =
  updateUserRolesStatusSlice.actions;
export const updateUserRolesStatusReducer = updateUserRolesStatusSlice.reducer;
export const selectUpdateUserRolesStatusDataList = (state: RootState) => {
  return state.updateUserRolesStatusState?.updateUserRolesStatusList ?? [];
};
