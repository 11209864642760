
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";

const initialState: any = {
  userAssignRolesList: [],
};

export const userAssignRolesSlice = createSlice({
  name: "userAssignRolesState",
  initialState,
  reducers: {
    userAssignRolesData: (state, action: PayloadAction<string>) => {
      state.userAssignRolesList = [...state.userAssignRolesList, action.payload];
    },
    resetList: () => initialState,
  },
});


export const { userAssignRolesData, resetList } = userAssignRolesSlice.actions;

export default userAssignRolesSlice.reducer;

export const selectedUserAssignRolesList = (state: RootState) =>
  state.userAssignRolesState?.userAssignRolesList ?? [];

