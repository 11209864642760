import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "components/common/ApiUrl";
import { toastError, toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";

const initialState = {
  subjectList: [],
  isLoading: false,
  error: null,
};

interface SubjectDetails {
  title: string;
  sub_short_name: string;
  data_json: { grade_id: number | null; section_id: number | null }[];
}

// Create Subject Async here //
export const createSubjectAsync = createAsyncThunk(
  "subject/create",
  async (credentials: SubjectDetails, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/subject/create`,
        credentials,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Create Subject Slice here //

const subjectSlice = createSlice({
  name: "subjectState",
  initialState,
  reducers: {
    subjectData: (state: any, action) => {
      state.subjectList.push(action.payload);
    },
    resetSubjectList: () => initialState,
    clearSubjectError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSubjectAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createSubjectAsync.fulfilled, (state: any, action) => {
        state.isLoading = false;
        state.subjectList = [...state.subjectList, action.payload];
      })
      .addCase(createSubjectAsync.rejected, (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload.message;
      });
  },
});

// Selectors here //
export const { resetSubjectList, clearSubjectError } = subjectSlice.actions;
export default subjectSlice.reducer;

export const selectSubjectDataList = (state: RootState) =>
  state.subjectState?.subjectList ?? [];

// subject list //
interface Subject {
  id: number;
  title: string;
  subject_code: string;
  grades: string[];
  sections: string[];
  teacher: string[];
}

interface SubjectListResponse {
  subjects: Subject[];
  count: number;
}

interface FetchSubjectsArgs {
  gradeId?: number[];
  sectionId?: number[];
  subjectId?: number[];
  subjectCode?: string[];
  searchQuery?: string;
  page?: number;
  pageSize?: number;
}

interface ErrorPayload {
  message: string;
  status: number;
}

interface SubjectListState {
  subjectDataList: Subject[];
  isLoading: boolean;
  error: string | null;
  count: number;
}

const subjectListInitialState: SubjectListState = {
  subjectDataList: [],
  isLoading: false,
  error: null,
  count: 0,
};

// Subject List Async here //
export const SubjectListAsync = createAsyncThunk<
  SubjectListResponse,
  FetchSubjectsArgs
>(
  "subject/create/list",
  async (
    {
      gradeId,
      sectionId,
      subjectId,
      subjectCode,
      searchQuery,
      page,
      pageSize,
    }: FetchSubjectsArgs,
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");
    const reportToken = localStorage.getItem("reportToken");
    const reportdb_token = localStorage.getItem("reportdb_token");

    try {
      const filter: any = {};
      if (gradeId) filter.grade_id = gradeId;
      if (sectionId) filter.section_id = sectionId;
      if (subjectId) filter.id = subjectId;
      if (subjectCode && subjectCode.length > 0)
        filter.subject_code = subjectCode;
      if (searchQuery) filter.title = searchQuery;

      const range: any = {};
      if (page !== undefined) range.page = page;
      if (pageSize !== undefined) range.pageSize = pageSize;

      const response = await axios.post(
        `${API_BASE_URL}/subject/list`,
        {
          filter,
          range: Object.keys(range).length ? range : undefined,
        },
        {
          headers: {
            Authorization: `Bearer ${token || SToken || reportToken}`,
            "db-token": db_token || Sdb_token || reportdb_token,
          },
        }
      );

      if (response?.data?.success) {
        return {
          subjects: Object.values(response?.data?.formattedSubjects),
          count: response?.data?.count,
        };
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Subject List Slice here //

export const subjectListSlice = createSlice({
  name: "subjectListState",
  initialState: subjectListInitialState,
  reducers: {
    clearSubjectList: () => subjectListInitialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(SubjectListAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        SubjectListAsync.fulfilled,
        (state, action: PayloadAction<SubjectListResponse>) => {
          state.isLoading = false;
          state.subjectDataList = action.payload.subjects;
          state.count = action.payload.count;
        }
      )
      .addCase(SubjectListAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as ErrorPayload).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

export const DisplaySubjectDataList = (state: RootState) =>
  state.subjectListState.subjectDataList ?? [];
export const selectSubjectListCount = (state: RootState) =>
  state.subjectListState.count;

// // update subject values//

interface ErrorPayload {
  message: string;
  status: number;
}
interface UpdateSubjectDetails {
  [x: string]: any;
  data_json: { grade_id: number | null; section_id: number | null }[];
  title: string;
  sub_short_name: string;
  description: string;
  max_class_per_week: number;
  type_of_subject: string;
}

interface UpdateSubjectState {
  updateSubjectList: UpdateSubjectDetails[];
  isLoading: boolean;
  error: string | null;
}

const initialUpdatedState: UpdateSubjectState = {
  updateSubjectList: [],
  isLoading: false,
  error: null,
};

// Update Subject Async here //
export const updateSubjectAsync = createAsyncThunk(
  "subject/update",
  async (
    { id, subjectDetails }: { id: any; subjectDetails: UpdateSubjectDetails },
    { rejectWithValue }
  ) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/subject/update/${id}`,
        subjectDetails,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// update subject slice here//

export const updateSubjectSlice = createSlice({
  name: "updateSubjectState",
  initialState: initialUpdatedState,
  reducers: {
    clearUpdatedSubject: (state) => {
      state.updateSubjectList = [];
      state.error = null;
      state.isLoading = false;
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(updateSubjectAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        updateSubjectAsync.fulfilled,
        (state, action: PayloadAction<UpdateSubjectDetails>) => {
          state.isLoading = false;
          const index = state.updateSubjectList.findIndex(
            (subject) => subject.id === action.payload.id
          );
          if (index !== -1) {
            state.updateSubjectList[index] = action.payload;
          }
        }
      )
      .addCase(
        updateSubjectAsync.rejected,
        (state, action: PayloadAction<ErrorPayload | SerializedError>) => {
          state.isLoading = false;
          state.error = action.payload.message || null;
        }
      );
  },
});

// Selectors here //
export const { clearUpdatedSubject } = updateSubjectSlice.actions;
export const updateSubjectReducer = updateSubjectSlice.reducer;
export const selectUpdateSubjectDataList = (state: RootState) => {
  return state.updateSubjectState?.updateSubjectList ?? [];
};

//Delete subject Value //
interface ErrorPayload {
  message: string;
  status: number;
}

interface DeleteSubjectState {
  isLoading: boolean;
  error: string | null;
}

const initialDeleteState: DeleteSubjectState = {
  isLoading: false,
  error: null,
};

// Delete Subject Async here //

export const deleteSubjectAsync = createAsyncThunk(
  "subject/delete",
  async (id: string, { rejectWithValue }) => {
    const token = localStorage.getItem("client_token");
    const db_token = localStorage.getItem("client_db_token");
    const SToken = localStorage.getItem("S_token");
    const Sdb_token = localStorage.getItem("S_db_token");

    try {
      const response = await axios.post(
        `${API_BASE_URL}/subject/delete/${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token || SToken}`,
            "db-token": db_token || Sdb_token,
          },
        }
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
      } else {
        toastError(response?.data?.message);
        return rejectWithValue(response?.data);
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);

// Delete Subject Slice here //

export const deleteSubjectSlice = createSlice({
  name: "deleteSubjectState",
  initialState: initialDeleteState,
  reducers: {
    clearDeleteSubject: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteSubjectAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteSubjectAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteSubjectAsync.rejected, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.error = (action.payload as { message: string }).message;
        } else {
          state.error = "Unknown error occurred";
        }
      });
  },
});

// Selectors here //
export const { clearDeleteSubject } = deleteSubjectSlice.actions;
export const deleteSubjectReducer = deleteSubjectSlice.reducer;
