import {
    createAsyncThunk,
    createSlice,
    PayloadAction,
    SerializedError,
  } from "@reduxjs/toolkit";
  import axios from "axios";
  import { API_BASE_URL, SUB_DOMAIN } from "components/common/ApiUrl";
  import { toastError, toastSuccess } from "helpers/toastHelper";
  import { RootState } from "redux/store";
  
  interface ForgotPasswordState {
    isLoading: boolean;
    error: string | null;
    successMessage: string | null;
  }
  
  interface ForgotPasswordPayload {
    email: string;
    subdomain: string;
  }
  
  const initialState: ForgotPasswordState = {
    isLoading: false,
    error: null,
    successMessage: null,
  };

  
  // Forgot Password Async here //
  export const forgotPasswordAsync = createAsyncThunk<
  string,
  ForgotPasswordPayload,
  { rejectValue: { message: string; status: number } }
>(
  "auth/forgotPassword",
  async (credentials, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "sub-domain": credentials.subdomain,
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/forgot-password`,
        { email: credentials.email },
        config 
      );

      if (response?.data?.success) {
        toastSuccess(response?.data?.message);
        return response?.data?.message;
      } else {
        toastError(response?.data?.message);
        return rejectWithValue({ message: response?.data?.message, status: 400 });
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message;
      toastError(errorMessage);
      return rejectWithValue({
        message: errorMessage,
        status: error.response?.status,
      });
    }
  }
);



  // Forgot Password Slice here //

const forgotPasswordSlice = createSlice({
    name: "forgotPasswordState",
    initialState,
    reducers: {
      clearForgotPasswordError: (state) => {
        state.error = null;
      },
      clearForgotPasswordSuccess: (state) => {
        state.successMessage = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(forgotPasswordAsync.pending, (state) => {
          state.isLoading = true;
          state.error = null;
          state.successMessage = null;
        })
        .addCase(forgotPasswordAsync.fulfilled, (state, action) => {
          state.isLoading = false;
          state.successMessage = action.payload;
        })
        .addCase(forgotPasswordAsync.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload?.message;
        });
    },
  });

  // Selectors //
  export const { clearForgotPasswordError, clearForgotPasswordSuccess } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;

export const selectForgotPasswordState = (state: RootState) =>
  state.forgotPasswordState;
export const selectForgotPasswordError = (state: RootState) =>
  state.forgotPasswordState.error;
export const selectForgotPasswordSuccessMessage = (state: RootState) =>
  state.forgotPasswordState.successMessage;
